<template>
    <p class="tw-text-2xl tw-text-green-900" > {{ $t('views.profile.sugest.searchedProducts') }} </p>
    <p class="tw-text-2xl tw-text-green-900" > {{ $t('views.profile.sugest.recentlyseen') }} </p>

</template>


<script setup lang="ts">

</script>


<style scoped>

</style>
