<template>
    <div class="tw-flex tw-flex-col tw-gap-8 max-lg:tw-px-[var(--padding-left)] max-lg:tw-py-[var(--padding-top)]">
        <p class="tw-text-center tw-text-green-900 xl:tw-mb-4 lg:tw-text-5xl max-lg:tw-text-2xl">{{ $t('views.partners.tituloPartners') }}</p>
        <div class="tw-flex tw-flex-row justify-center tw-gap-4">
        <v-text-field
            v-model="searchText"
            placeholder="pesquisar"
            hide-details
            @keydown.enter="onSearch"
            class="search-input tw-w-full tw-max-w-4xl">
            <template #append-inner>
                <v-btn
                    class="tw-absolute tw-right-0 tw-mr-1 tw-max-h-none tw-bg-green-900 tw-px-10 tw-text-white max-md:tw-hidden lg:tw-h-5/6"
                    @click="onSearch"
                    >{{ $t('views.shippingCompany.shippings.search') }}</v-btn
                >
            </template>
        </v-text-field>

        <div class="tw-flex tw-items-center">
            <v-btn
                class="lg:tw-h-5/6"
                color="green-900"
                variant="outlined">
                <v-icon> mdi-filter-outline </v-icon>
                <p class="max-md:tw-hidden">{{ $t('views.shippingCompany.shippings.filters') }}</p>
            </v-btn>
        </div>
    </div>
    </div>

    <div class="tw-relative tw-grid tw-grid-cols-2 tw-gap-5 md:tw-grid-cols-4 2xl:tw-gap-x-10 2xl:tw-gap-y-20 tw-pl-[var(--padding-left)] tw-pt-[var(--padding-top)] tw-pb-[var(--padding-bottom)] tw-pr-[var(--padding-right)]">
        <CardPartner
            v-for="partner in partners"
            :key="partner.id"
            :partner="partner" />
        <v-overlay
            :model-value="isLoading"
            contained
            absolute
            :close-on-back="false"
            :close-on-content-click="false"
            :persistent="true"
            class="tw-items-center tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { computed } from 'vue';
    import { watch } from 'vue';
    import CardPartner from './CardPartner.vue';
    import { ContentType, APIResponseMany } from '../../contentTypes';
    import axios from '@axios';
    import qs from 'qs';
    import type { AxiosResponse } from 'axios';
    import { useI18n } from 'vue-i18n';

    const  {locale} = useI18n();

    const searchText = ref('');
    const searchQuery = ref('');

    function onSearch() {
        searchQuery.value = searchText.value;
        getPartners();
    }

    const partners = ref<ContentType<'Partner'>[]>([]);
    const isLoading = ref(true);

    async function getPartners() {
        isLoading.value = true;
        try {
            const query = qs.stringify({
                populate: ['picture'],
                filters: {
                    name: {
                        $containsi: searchQuery.value,
                    },
                },
            });
            const response = (await axios.get(`/partners?${query}`)) as AxiosResponse<APIResponseMany<'Partner'>>;
            partners.value = response.data.data;
        } catch (error) {
            console.error('Error fetching partners details:', error);
        } finally {
            isLoading.value = false;
        }
    }

    watch(locale, () => {
        getPartners();
    });
    getPartners();
</script>

<style>
    @media screen and (min-width: 1024px) {
        .search-input {
            input {
                padding-block: 1.25rem !important;
                padding-left: 2rem !important;
                padding-right: 1rem !important;
            }
        }
    }
</style>
