<template>
    <div
        id="map"
        class="tw-w-full tw-bg-secondary"></div>
</template>

<script setup lang="ts">
    import { PropType, ref, onMounted } from 'vue';
    import { Product } from '../../contentTypes';
    import { Loader } from '@googlemaps/js-api-loader';
    import { watch } from 'vue';
    import axios from '@axios';
    import qs from 'qs';

    const props = defineProps({
        query: {
            type: Object,
            default: () => ({}),
        },
    });

    const products = ref<Product[]>([]);
    const isLoading = ref(true);

    const map = ref<google.maps.Map>();
    // GOOGLE MAPS
    const loader = new Loader({
        apiKey: import.meta.env.VITE_GMAPS_KEY,
    });
    const MapsLibrary = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;
    const MarkerLibrary = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;

    async function getProducts() {
        isLoading.value = true;
        try {
            const query = qs.stringify({
                populate: ['category', 'pictures', 'vendor'],
                ...props.query,
            });
            const response = await axios.get(`/products?${query}`);
            products.value = response.data.data;
            mountMarkers();
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            isLoading.value = false;
        }
    }

    function createRoundImageIcon(imageUrl: string) {
        return {
            url: imageUrl,
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(20, 20),
            shape: {
                coords: [20, 20, 20],
                type: 'circle',
            },
        };
    }


    function mountMarkers() {
        if (!map.value || products.value.length === 0) return;

        products.value
            .filter((product) => product.vendor?.latitude && product.vendor?.longitude)
            .forEach((product) => {
                const firstImage = product.pictures?.[0]?.formats?.thumbnail?.url;
                const imageUrl = firstImage ? `https://api.beat.iotechpis.com${firstImage}` : 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';

                const customIcon = createRoundImageIcon(imageUrl);

                new MarkerLibrary.Marker({
                    position: { lat: product.vendor.latitude, lng: product.vendor.longitude },
                    map: map.value,
                    title: product.name,
                    icon: customIcon,
                });
            });
    }

    watch([() => props.query], () => {
        getProducts();
    });

    getProducts();

    onMounted(async () => {
        const position: GeolocationPosition = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        map.value = new MapsLibrary.Map(document.getElementById('map') as HTMLElement, {
            center: { lat: position.coords.latitude, lng: position.coords.longitude },
            zoom: 12,
            // disableDefaultUI: true,
            gestureHandling: 'greedy',
        });

        mountMarkers();
    });
</script>

<style>
    #map {
        height: calc(100dvh - 6rem);
    }
</style>
