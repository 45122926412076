<template>
    <v-footer
        color="green-900"
        class="tw-flex-col tw-justify-end">
        <div class="tw-w-full tw-grid-cols-7 md:tw-grid md:tw-grow">
            <v-img
                class="tw-col-span-2 tw-my-8 tw-ml-4 tw-max-w-[15rem] tw-self-center sm:tw-pl-0 md:tw-my-0 lg:tw-col-span-2 lg:tw-max-w-[27rem]"
                src="/logo.svg"></v-img>
            <div class="tw-col-span-4 tw-col-start-4 tw-self-end 2xl:tw-col-span-3 2xl:tw-col-start-5">
                <div class="tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-justify-end tw-gap-4 max-sm:tw-justify-start md:tw-pt-8">
                    <a
                        href="https://www.youtube.com/channel/UCBFE7W-XStfy9yqcYU4587Q"
                        target="_blank"
                        class="tw-rounded-full tw-border tw-border-secondary tw-p-2"
                        rel="noopener noreferrer">
                        <v-icon
                            color="secondary"
                            size="x-large">
                            mdi-youtube
                        </v-icon>
                    </a>
                    <a
                        href="https://x.com/Beat_textiles"
                        target="_blank"
                        class="tw-rounded-full tw-border tw-border-secondary tw-p-2"
                        rel="noopener noreferrer">
                        <v-img
                            width="32"
                            src="@/assets/icons/x.svg">
                        </v-img
                    ></a>

                    <a
                        href="https://www.facebook.com/profile.php?id=100086689429810"
                        target="_blank"
                        class="tw-rounded-full tw-border tw-border-secondary tw-p-2"
                        rel="noopener noreferrer">
                        <v-icon
                            color="secondary"
                            size="x-large">
                            mdi-facebook
                        </v-icon>
                    </a>

                    <a
                        href="https://www.instagram.com/beat_textiles/"
                        target="_blank"
                        class="tw-rounded-full tw-border tw-border-secondary tw-p-2"
                        rel="noopener noreferrer">
                        <v-icon
                            color="secondary"
                            size="x-large">
                            mdi-instagram
                        </v-icon>
                    </a>

                    <a
                        href="https://www.linkedin.com/showcase/bioeconomyattextiles"
                        target="_blank"
                        class="tw-rounded-full tw-border tw-border-secondary tw-p-2"
                        rel="noopener noreferrer">
                        <v-icon
                            color="secondary"
                            size="x-large">
                            mdi-linkedin
                        </v-icon>
                    </a>
                </div>

                <p class="tw-mb-4 tw-text-end tw-text-2xl tw-font-light tw-text-secondary max-sm:tw-text-start md:tw-pt-5 md:tw-text-3xl lg:tw-mb-10 lg:tw-text-4xl">
                    {{ $t('footer.subscribe') }}
                </p>
                <div
                    class="tw-flex tw-items-center tw-bg-secondary tw-py-2 tw-pl-3 tw-pr-4 max-sm:tw-flex-row-reverse max-sm:tw-rounded-e-full sm:tw-rounded-s-full sm:tw-pr-8 lg:tw-pl-6 2xl:tw-pr-32">
                    <v-img
                        class="tw-max-w-[5rem] lg:tw-max-w-[8.5rem]"
                        width="100%"
                        :aspect-ratio="1"
                        src="@/assets/images/footer/symbol.svg"></v-img>
                    <v-text-field
                        class="newsletter-input tw-ml-4 tw-w-full tw-font-light placeholder:tw-opacity-100"
                        placeholder="Insira o seu email"
                        outlined
                        variant="underlined"
                        color="white"
                        hide-details
                        rounded
                        dense></v-text-field>
                </div>
            </div>
        </div>
        <div class="tw-my-20 tw-flex tw-w-full tw-grow tw-flex-wrap tw-justify-center tw-gap-8 md:tw-grow-0 md:tw-justify-end">
            <v-img
                max-width="150"
                width="100%"
                src="@/assets/images/footer/citeve.svg"></v-img>
            <v-img
                max-width="150"
                width="100%"
                src="@/assets/images/footer/bioeconomia.png"></v-img>
            <v-img
                max-width="150"
                width="100%"
                src="@/assets/images/footer/prr.png"></v-img>
            <v-img
                max-width="150"
                width="100%"
                src="@/assets/images/footer/rep-portuguesa.png"></v-img>
            <v-img
                max-width="150"
                width="100%"
                src="@/assets/images/footer/Financiado-pela-Unio-Europeia.png"></v-img>
            <v-img
                max-width="150"
                width="100%"
                src="@/assets/images/footer/fundo-ambiental.png"></v-img>
        </div>
    </v-footer>
</template>

<script setup lang="ts"></script>

<style>
    .newsletter-input input {
        @apply tw-text-2xl lg:tw-text-3xl;
    }

    .newsletter-input .v-field__outline {
        display: none !important;
    }

    .newsletter-input input::placeholder {
        opacity: 1 !important;
    }
</style>
