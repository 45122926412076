<template>
    <div>
        <div class="tw-flex tw-items-center sm:tw-hidden">
            <router-link to="/profile">
                <v-btn
                    class="tw-text-2xl"
                    icon="mdi-chevron-left"
                    variant="tonal"
                    color="green-900"></v-btn>
            </router-link>

            <h1 class="tw-pl-4 tw-text-2xl">{{ $t('views.profile.favorites.favorites') }}</h1>
        </div>

        <form class="tw-pb-4">
            <v-btn
                class="tw-mr-5"
                variant="text"
                @click="showAll"
                >{{ $t('views.profile.favorites.all') }}</v-btn
            >
            <v-btn
                class="tw-mr-5"
                variant="text"
                @click="showProductsOnly"
                >{{ $t('views.profile.favorites.products') }}</v-btn
            >
            <v-btn
                class="tw-mr-5"
                variant="text"
                @click="showSuppliersOnly"
                >{{ $t('views.profile.favorites.suppliers') }}</v-btn
            >
            <v-btn
                class="tw-mr-5"
                variant="text"
                @click="showPartnersOnly"
                >{{ $t('views.profile.favorites.partners') }}</v-btn
            >
        </form>

        <div class="tw-grid tw-gap-4 max-lg:tw-grid-cols-2 lg:tw-grid-cols-4">
            <!-- Cards serão exibidos condicionalmente com v-if -->
            <CardProduct
                v-if="showProducts"
                v-for="product in favoriteProducts"
                :key="product.id"
                :product="product"
                class="tw-mb-4 lg:tw-mb-0" />

            <CardPartner
                v-if="showPartners"
                v-for="partner in favoritePartners"
                :key="partner.id"
                :partner="partner"
                class="tw-mb-4 lg:tw-mb-0" />

            <CardSupplier
                v-if="showSuppliers"
                v-for="supplier in favoriteSuppliers"
                :key="supplier.id"
                :supplier="supplier"
                class="tw-mb-4 lg:tw-mb-0" />

            <v-overlay
                :model-value="isLoading"
                contained
                absolute
                :close-on-back="false"
                :close-on-content-click="false"
                :persistent="true"
                class="tw-items-center tw-justify-center">
                <v-progress-circular
                    indeterminate
                    color="primary"></v-progress-circular>
            </v-overlay>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref, watch, defineProps } from 'vue';
    import CardProduct from '../Products/CardProduct.vue';
    import CardSupplier from '../Suppliers/CardSupplier.vue';
    import CardPartner from '../Partners/CardPartner.vue';
    import { Product } from '@/contentTypes';
    import { Supplier } from '@/contentTypes';
    import { Partner } from '@/contentTypes';
    import axios from '@axios';
    import qs from 'qs';

    const props = defineProps({
        query: {
            type: Object,
            default: () => ({}),
        },
    });

    const products = ref<Product[]>([]);
    const suppliers = ref<Supplier[]>([]);
    const partners = ref<Partner[]>([]);
    const isLoading = ref(true);

    const showProducts = ref(true); //ta true para a pagina nao ficar vazia enquanto nenhum botao estiver clicado
    const showSuppliers = ref(true);
    const showPartners = ref(true);

    async function getProducts() {
        isLoading.value = true;
        try {
            const query = qs.stringify({
                populate: ['category', 'pictures'],
                ...props.query,
            });
            const response = await axios.get(`/products?${query}`);
            products.value = response.data.data.filter((product: { favoriteId: null }) => product.favoriteId !== null);
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            isLoading.value = false;
        }
    }

    const favoriteProducts = computed(() => {
        return products.value;
    });

    //-------------------

    async function getSuppliers() {
        isLoading.value = true;
        try {
            const query = qs.stringify({
                populate: ['picture'],
                ...props.query,
            });
            const response = await axios.get(`/vendors?${query}`);
            suppliers.value = response.data.data.filter((supplier: { favoriteId: null }) => supplier.favoriteId !== null);
        } catch (error) {
            console.error('Error fetching supplier details:', error);
        } finally {
            isLoading.value = false;
        }
    }

    const favoriteSuppliers = computed(() => {
        return suppliers.value;
    });

    //-------------------
    async function getPartners() {
        isLoading.value = true;
        try {
            const query = qs.stringify({
                populate: ['picture'],
                ...props.query,
            });
            const response = await axios.get(`/partners?${query}`);
            partners.value = response.data.data.filter((partner: { favoriteId: null }) => partner.favoriteId !== null);
        } catch (error) {
            console.error('Error fetching partner details:', error);
        } finally {
            isLoading.value = false;
        }
    }

    const favoritePartners = computed(() => {
        return partners.value;
    });

    //-------------------------------

    watch([() => props.query], () => {
        getProducts();
        getSuppliers();
        getPartners();
    });

    getProducts();
    getSuppliers();
    getPartners();

    function showProductsOnly() {
        showProducts.value = true;
        showSuppliers.value = false;
        showPartners.value = false;
    }

    function showSuppliersOnly() {
        showProducts.value = false;
        showSuppliers.value = true;
        showPartners.value = false;
    }

    function showPartnersOnly() {
        showProducts.value = false;
        showSuppliers.value = false;
        showPartners.value = true;
    }

    function showAll() {
        showProducts.value = true;
        showSuppliers.value = true;
        showPartners.value = true;
    }
</script>

<style scoped>
    form :focus {
        color: brown;
        background-color: rgb(var(--v-theme-primary));
        color: rgb(var(--v-theme-green-900));
    }
</style>
