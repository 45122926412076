<template>
    <div class="tw-flex max-lg:tw-flex-col max-lg:tw-justify-center lg:tw-items-start lg:tw-gap-8 lg:tw-px-[var(--padding-left)] 2xl:tw-gap-16">
        <io-img
            :aspect-ratio="1"
            contain
            class="tw-w-full tw-border tw-border-gray-300 max-lg:tw-mx-auto max-lg:tw-max-h-72 max-sm:tw-w-full sm:tw-mx-0 sm:tw-max-w-full md:tw-rounded-4xl lg:tw-max-w-xs 2xl:tw-max-w-md"
            :media="firstPicture"
            content-type="ShippingCompany"
            format="medium">
        </io-img>

        <div class="tw-relative tw-flex tw-flex-col tw-bg-pink max-md:-tw-mt-8 max-md:tw-rounded-4xl max-md:tw-p-6 md:max-lg:tw-mt-6 lg:tw-items-start">
            <h1 class="tw-text-2xl tw-text-green-900 max-md:tw-mt-4 md:tw-text-3xl 2xl:tw-text-5xl">{{ shipping?.name }}</h1>

            <div class="tw-mt-3 tw-flex tw-items-center">
                <v-icon
                    class="tw-rounded-lg tw-bg-secondary tw-p-4"
                    color="white"
                    >mdi-map-marker</v-icon
                >
                <h4 class="tw-pl-2 tw-text-lg 2xl:tw-text-2xl">Porto, Portugal</h4>
            </div>
            <h6 class="tw-mt-4 tw-pt-2 tw-text-lg 2xl:tw-text-xl">{{ $t('views.shippingCompany.shipping.description') }}</h6>
            <h6 class="2xl:tw-text-xl">{{ shipping?.description }}</h6>

            <div
                v-if="shipping?.keywords && shipping.keywords.length > 0"
                class="tw-mb-2 tw-mt-4">
                <span
                    class="tw-border-gray-500 tw-mr-2 tw-w-max tw-flex-wrap tw-rounded-md tw-bg-primary tw-p-2"
                    v-for="(slug, index) in shipping.keywords.split(',').map((keyword) => keyword.trim())"
                    :key="index">
                    {{ slug }}
                </span>
            </div>

            <div class="tw-w-full tw-items-center tw-justify-between tw-bg-pink">
                <div
                    class="tw-z-10 tw-flex tw-gap-4 max-lg:tw-fixed max-lg:tw-bottom-0 max-lg:tw-left-0 max-lg:tw-flex max-lg:tw-w-full max-lg:tw-items-center max-lg:tw-justify-between max-lg:tw-border-t-2 max-lg:tw-border-solid max-lg:tw-border-gray-300 max-lg:tw-bg-pink max-lg:tw-p-4 lg:tw-mt-6 lg:tw-flex lg:tw-flex-col">
                    <div class="tw-order-2 tw-flex tw-w-full tw-items-center tw-gap-4 max-lg:tw-justify-center">
                        <v-dialog
                            v-model="dialog"
                            persistent
                            width="1024">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="tw-bg-green-900 tw-px-12 tw-text-sm tw-font-bold tw-text-white"
                                    size="x-large"
                                    v-bind="props">
                                    {{ $t('views.shippingCompany.shipping.contact') }}
                                </v-btn>
                            </template>
                            <v-card class="tw-p-4">
                                <v-card-title>
                                    <span class="text-h5">{{ $t('views.shippingCompany.shipping.contact') }} {{ shipping?.name }} </span>
                                </v-card-title>


                               

                                <v-card-text class="tw-flex tw-flex-col">
                               
                                    <label> Mensagem: </label>

                                    <v-textarea
                                        class="tw-pt-2"
                                        required></v-textarea>
                                </v-card-text>


                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue-darken-1"
                                        variant="text"
                                        @click="closeDialog"
                                        >{{ $t('views.shippingCompany.shipping.close') }}</v-btn
                                    >
                                    <v-btn
                                        color="blue-darken-1"
                                        variant="text"
                                        @click="closeDialog"
                                        >{{ $t('views.shippingCompany.shipping.contact') }}</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-btn
                            v-if="authStore.isAuthenticated"
                            variant="text"
                            icon
                            color="white"
                            class="tw-h-auto tw-max-h-none tw-w-auto tw-p-2 max-lg:tw-hidden"
                            @click="toggleFavorite">
                            <v-icon
                                class="tw-text-5xl md:tw-text-4xl"
                                :color="isFavorite ? 'secondary' : 'green-900'">
                                {{ isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
            <v-btn
                v-if="authStore.isAuthenticated"
                @click="toggleFavorite"
                variant="text"
                icon
                color="white"
                class="tw-h-auto tw-max-h-none tw-w-auto tw-p-2 max-lg:tw-absolute max-lg:tw-right-0 max-lg:tw-top-0 max-lg:-tw-translate-x-1/3 max-lg:-tw-translate-y-1/2 max-lg:tw-bg-white lg:tw-hidden">
                <v-icon
                    class="tw-text-5xl lg:tw-text-4xl"
                    :color="isFavorite ? 'secondary' : 'green-900'">
                    {{ isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                </v-icon>
            </v-btn>

            <div class="max-lg:tw-absolute max-lg:tw-left-7 max-lg:tw-top-5 max-lg:-tw-translate-x-1/3 max-lg:-tw-translate-y-1/2 lg:tw-hidden">
                <router-link to="/shippings"> <v-icon>mdi-arrow-left</v-icon> </router-link>
            </div>
        </div>
    </div>
    <h1 class="tw-px-[var(--padding-left)] tw-pt-[var(--padding-top)] tw-text-2xl tw-text-green-900">{{ $t('views.shippingCompany.shipping.sugestions') }}</h1>

    <div
        class="grid tw-px-[var(--padding-left)] tw-pb-24"
        v-if="shippings.length > 0">
        <CardShipping
            v-for="shipping in shippings"
            :query="querySuggestions"
            :key="shipping.id"
            :shipping="shipping" />
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import axios from '@axios';
    import qs from 'qs';
    import type { AxiosResponse } from 'axios';
    import { ContentType, ShippingCompany, APIResponseSingle, Media } from '../../contentTypes';
    import CardShipping from './CardShipping.vue';
    import ioImg from '@/components/ioImg.vue';
    import { PropType, defineProps } from 'vue';
    import { computed } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import { useFavorites } from '@/composables/useFavorites';
    const dialog = ref(false);

    const closeDialog = () => {
        dialog.value = false;
    };

    const shippings = ref<ContentType<'ShippingCompany'>[]>([]);
    const shipping = ref<ShippingCompany>();

    const authStore = useAuthStore();
    const $favorites = useFavorites();
    const isLoading = ref(true);
    const route = useRoute();

    const isFavorite = computed(() => {
        return !!shipping.value?.favoriteId;
    });

    const querySuggestions = computed(() => ({
        pagination: {
            pageSize: 4,
        },
        filters: {
            id: {
                $ne: route.params.id,
            },
        },
    }));

    watch(
        [() => route.params.id],
        () => {
            console.log('route.params.id:', route.params.id);

            fetchShipping();
        },
        {
            flush: 'post',
        },
    );

    onMounted(async () => {
        await fetchShipping();
        await fetchShippings();
    });

    const fetchShipping = async () => {
        try {
            const query = qs.stringify({
                populate: ['picture'],
            });
            const response = await axios.get(`/shipping-companies/${route.params.id}?${query}`);
            shipping.value = response.data.data;
        } catch (error) {
            console.error('Error fetching shipping details:', error);
        } finally {
            isLoading.value = false;
        }
    };

    const fetchShippings = async () => {
        try {
            const query = qs.stringify({
                populate: ['picture'],
            });
            const response = await axios.get(`/shipping-companies?${query}`, {
                params: {
                    ...querySuggestions.value,
                },
            });
            shippings.value = response.data.data;
        } catch (error) {
            console.error('Error fetching shippings:', error);
        }
    };

    const firstPicture = computed(() => {
        return (shipping.value?.picture as Media) || undefined;
    });

    const toggleFavorite = async () => {
        try {
            if (!isFavorite.value) {
                const { data } = await $favorites.addFavorite('shipping_company', route.params.id as string);
                shipping.value = {
                    ...shipping.value,
                    favoriteId: data.data.id || null,
                    keywords: shipping.value?.keywords || '',
                    keywordsSlug: shipping.value?.keywordsSlug || '',
                };
            } else {
                await $favorites.removeFavorite(shipping.value?.favoriteId as number);
                shipping.value = {
                    ...shipping.value,
                    favoriteId: undefined || null,
                    keywords: shipping.value?.keywords || '',
                    keywordsSlug: shipping.value?.keywordsSlug || '',
                };
            }
        } catch (error) {
            console.error('Erro ao favoritar/desfavoritar o shipping:', error);
        }
    };
</script>

<style scoped></style>
