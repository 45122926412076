export enum User_OperationArea {
    OperationArea1 = 'operationArea1',
    OperationArea2 = 'operationArea2',
    OperationArea3 = 'operationArea3',
    OperationArea4 = 'operationArea4',
}

export enum MatchPreference_MatterState {
    Solid = 'solid',
    Liquid = 'liquid',
}
export enum MatchPreference_Nature {
    RenewableSource = 'renewable source',
    BioBasedContent = 'bio-based content',
    Recycled = 'recycled',
}

export enum Notification_Type {
    NewOrder = 'newOrder',
    OrderAccepted = 'orderAccepted',
    OrderRejected = 'orderRejected',
}

export enum Product_Type {
    Textiles = 'textiles',
    OtherIndustries = 'other industries',
    TextileAccessories = 'textile accessories',
}
export enum Product_MatterState {
    Solid = 'solid',
    Liquid = 'liquid',
}
export enum Product_Nature {
    RenewableSource = 'renewable source',
    BioBasedContent = 'bio-based content',
    Recycled = 'recycled',
}
export enum Product_QuantityType {
    L = 'l',
    M = 'm',
    Kg = 'kg',
    Unit = 'unit',
}
export enum Product_Color {
    Red = 'red',
    Orange = 'orange',
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Purple = 'purple',
    Pink = 'pink',
    Brown = 'brown',
    Beige = 'beige',
    White = 'white',
    Black = 'black',
    Gray = 'gray',
    Silver = 'silver',
}
export enum Product_Season {
    Spring = 'spring',
    Summer = 'summer',
    Fall = 'fall',
    Winter = 'winter',
}

export enum Support_Status {
    Open = 'Open',
    InProgress = 'In Progress',
    Solved = 'Solved',
}

export interface Media<P extends boolean = true> {
    id?: number;
    name: string;
    alternativeText?: string;
    caption?: string;
    width?: number | null;
    height?: number | null;
    formats: { thumbnail: MediaFormat; small: MediaFormat; medium: MediaFormat; large: MediaFormat };
    hash: string;
    ext?: string;
    mime: string;
    size: number | null;
    url: string;
    previewUrl?: string;
    provider: string;
    provider_metadata?: any;
    folderPath: string;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Role<P extends boolean = true> {
    id?: number;
    name: string;
    description?: string;
    type?: string;
    users?: P extends true ? User<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface User<P extends boolean = true> {
    id?: number;
    username: string;
    email: string;
    provider?: string;
    password?: string;
    resetPasswordToken?: string;
    confirmationToken?: string;
    confirmed?: boolean;
    blocked?: boolean;
    role?: P extends true ? Role<P> | null : number | null;
    picture?: P extends true ? Media<P> | null : number | null;
    firstName?: string;
    lastName?: string;
    orders?: P extends true ? Order<P>[] : number[];
    ecoPoints?: number | null;
    notifications?: P extends true ? Notification<P>[] : number[];
    support?: P extends true ? Support<P> | null : number | null;
    like?: P extends true ? Like<P> | null : number | null;
    cart?: P extends true ? Cart<P> | null : number | null;
    matchPreference?: P extends true ? MatchPreference<P> | null : number | null;
    favorites?: P extends true ? Favorite<P>[] : number[];
    supplier?: P extends true ? Supplier<P> | null : number | null;
    fcmToken?: string;
    shippingRequests?: P extends true ? ShippingRequest<P>[] : number[];
    shippingCompanies?: P extends true ? ShippingCompany<P>[] : number[];
    products?: P extends true ? Product<P>[] : number[];
    operationArea?: User_OperationArea;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface UserShipping<P extends boolean = true> {
    address?: string;
    country?: string;
    zipCode?: string;
    doorNumber?: string;
    city: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    user?: P extends true ? User<P> | null : number | null;
}

export interface Cart<P extends boolean = true> {
    id?: number;
    user?: P extends true ? User<P> | null : number | null;
    open?: boolean;
    total: number;
    cartProducts?: P extends true ? CartProduct<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface CartProduct<P extends boolean = true> {
    id?: number;
    product?: P extends true ? Product<P> | null : number | null;
    quantity?: number | null;
    cart?: P extends true ? Cart<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Category<P extends boolean = true> {
    id?: number;
    products?: P extends true ? Product<P>[] : number[];
    name?: string;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Favorite<P extends boolean = true> {
    id?: number;
    type?: string;
    product?: P extends true ? Product<P> | null : number | null;
    user?: P extends true ? User<P> | null : number | null;
    supplier?: P extends true ? Supplier<P> | null : number | null;
    partner?: P extends true ? Partner<P> | null : number | null;
    shipping_company?: P extends true ? ShippingCompany<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Kpi<P extends boolean = true> {
    id?: number;
    name?: string;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Like<P extends boolean = true> {
    id?: number;
    user?: P extends true ? User<P> | null : number | null;
    product?: P extends true ? Product<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Location<P extends boolean = true> {
    id?: number;
    name?: string;
    level?: number | null;
    slug?: string;
    code?: string;
    latitude?: number | null;
    longitude?: number | null;
    supplierss?: P extends true ? Supplier<P>[] : number[];
    children?: P extends true ? Location<P>[] : number[];
    parent?: P extends true ? Location<P> | null : number | null;
    shippingCompanies?: P extends true ? ShippingCompany<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Match<P extends boolean = true> {
    id?: number;
    products?: P extends true ? Product<P>[] : number[];
    match?: boolean;
    matchBy?: P extends true ? User<P> | null : number | null;
    matchTo?: P extends true ? User<P> | null : number | null;
    superlike?: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface MatchPreference<P extends boolean = true> {
    id?: number;
    user?: P extends true ? User<P> | null : number | null;
    price?: number | null;
    isFinished?: boolean;
    matterState?: MatchPreference_MatterState;
    nature?: MatchPreference_Nature;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Notification<P extends boolean = true> {
    id?: number;
    type?: Notification_Type;
    text?: string;
    wasViewed?: boolean;
    picture?: P extends true ? Media<P> | null : number | null;
    url?: string;
    user?: P extends true ? User<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Order<P extends boolean = true> {
    id?: number;
    status?: boolean;
    user?: P extends true ? User<P> | null : number | null;
    amount?: number | null;
    product?: P extends true ? Product<P> | null : number | null;
    shippingCompany?: P extends true ? ShippingCompany<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Partner<P extends boolean = true> {
[x: string]: Partner<true> | undefined;
products: Partner<true> | undefined;
    id?: number;
    name?: string;
    description?: string;
    picture?: P extends true ? Media<P> | null : number | null;
    keywords?: string;
    keywordsSlug?: string;
    favorites?: P extends true ? Favorite<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Product<P extends boolean = true> {
    id?: number;
    name?: string;
    price?: number | null;
    pictures?: P extends true ? Media<P>[] : number[];
    sku?: string;
    slug?: string;
    description?: string;
    isFinished?: boolean;
    type?: Product_Type;
    matterState?: Product_MatterState;
    nature?: Product_Nature;
    category?: P extends true ? Category<P> | null : number | null;
    favorites?: P extends true ? Favorite<P>[] : number[];
    deliveryTime?: number | null;
    orders?: P extends true ? Order<P>[] : number[];
    likes?: P extends true ? Like<P>[] : number[];
    shortCircuit?: boolean;
    productDetails?: any;
    cartProduct?: P extends true ? CartProduct<P> | null : number | null;
    matches?: P extends true ? Match<P>[] : number[];
    user?: P extends true ? User<P> | null : number | null;
    supplier?: P extends true ? Supplier<P> | null : number | null;
    latitude?: number | null;
    longitude?: number | null;
    quantity?: number | null;
    quantityType?: Product_QuantityType;
    color?: Product_Color;
    tags?: P extends true ? ProductTag<P>[] : number[];
    location?: P extends true ? Location<P> | null : number | null;
    season?: Product_Season;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface ProductTag<P extends boolean = true> {
    id?: number;
    products?: P extends true ? Product<P>[] : number[];
    value?: string;
    slug?: string;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface ProductTransformation<P extends boolean = true> {
    id?: number;
    name?: string;
    description?: string;
    parent?: P extends true ? ProductTransformation<P> | null : number | null;
    children?: P extends true ? ProductTransformation<P>[] : number[];
    level?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface ShippingCompany<P extends boolean = true> {
    id?: number;
    name?: string;
    location?: P extends true ? Location<P> | null : number | null;
    orders?: P extends true ? Order<P>[] : number[];
    description?: string;
    email?: string;
    telephone?: string;
    user?: P extends true ? User<P> | null : number | null;
    shippingRequests?: P extends true ? ShippingRequest<P>[] : number[];
    picture?: P extends true ? Media<P> | null : number | null;
    keywords?: string;
    keywordsSlug?: string;
    nipc?: string;
    favorites?: P extends true ? Favorite<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface ShippingRequest<P extends boolean = true> {
    id?: number;
    user?: P extends true ? User<P> | null : number | null;
    shippingCompany?: P extends true ? ShippingCompany<P> | null : number | null;
    status?: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Support<P extends boolean = true> {
    id?: number;
    description?: string;
    status: Support_Status;
    subject?: string;
    user?: P extends true ? User<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Supplier<P extends boolean = true> {
    id?: number;
    name?: string;
    picture?: P extends true ? Media<P> | null : number | null;
    description?: string;
    telephone?: string;
    email?: string;
    location?: P extends true ? Location<P> | null : number | null;
    latitude?: number | null;
    longitude?: number | null;
    user?: P extends true ? User<P> | null : number | null;
    products?: P extends true ? Product<P>[] : number[];
    keywords?: string;
    keywordsSlug?: string;
    favorites?: P extends true ? Favorite<P>[] : number[];
    nipc?: string;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface MediaFormat {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    width: number;
    height: number;
    size: number;
    path: string;
    url: string;
}
export interface ContentTypes<P extends boolean = true> {
    Media: Media<P>;
    Role: Role<P>;
    User: User<P>;
    Cart: Cart<P>;
    CartProduct: CartProduct<P>;
    Category: Category<P>;
    Favorite: Favorite<P>;
    Kpi: Kpi<P>;
    Like: Like<P>;
    Location: Location<P>;
    Match: Match<P>;
    MatchPreference: MatchPreference<P>;
    Notification: Notification<P>;
    Order: Order<P>;
    Partner: Partner<P>;
    Product: Product<P>;
    ProductTag: ProductTag<P>;
    ProductTransformation: ProductTransformation<P>;
    ShippingCompany: ShippingCompany<P>;
    ShippingRequest: ShippingRequest<P>;
    Support: Support<P>;
    Supplier: Supplier<P>;
}
export enum ContentTypesUID {
    Media = 'plugin::upload.file',
    Role = 'plugin::users-permissions.role',
    User = 'plugin::users-permissions.user',
    Cart = 'api::cart.cart',
    CartProduct = 'api::cart-product.cart-product',
    Category = 'api::category.category',
    Favorite = 'api::favorite.favorite',
    Kpi = 'api::kpi.kpi',
    Like = 'api::like.like',
    Location = 'api::location.location',
    Match = 'api::match.match',
    MatchPreference = 'api::match-preference.match-preference',
    Notification = 'api::notification.notification',
    Order = 'api::order.order',
    Partner = 'api::partner.partner',
    Product = 'api::product.product',
    ProductTag = 'api::product-tag.product-tag',
    ProductTransformation = 'api::product-transformation.product-transformation',
    ShippingCompany = 'api::shipping-company.shipping-company',
    ShippingRequest = 'api::shipping-request.shipping-request',
    Support = 'api::support.support',
    Supplier = 'api::supplier.supplier',
}
export type ContentType<T extends keyof ContentTypes, P extends boolean = true> = ContentTypes<P>[T];

export interface APIResponseMany<T extends keyof ContentTypes> {
    data: ContentType<T>[];
    meta: {
        pagination: {
            page: number;
            pageSize: number;
            pageCount: number;
            total: number;
        };
    };
}

export interface APIResponseSingle<T extends keyof ContentTypes> {
    data: ContentType<T>;
}

export interface APIRequestParams<T extends keyof ContentTypes> {
    populate?: any;
    fields?: (keyof ContentType<T, false>)[];
    locale?: string | string[];
    filters?: any;
    sort?:
        | `${string & keyof ContentType<T, false>}:asc`
        | `${string & keyof ContentType<T, false>}:desc`
        | (`${string & keyof ContentType<T, false>}:asc` | `${string & keyof ContentType<T, false>}:desc`)[];
    pagination?: {
        page?: number;
        pageSize?: number;
    };
}
