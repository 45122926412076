<template>
    <v-navigation-drawer v-model="isOpen">
        <div class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
            <h1 class="tw-text-xl tw-font-medium">{{ $t('views.products.filters') }}</h1>
            <p class="tw-flex tw-items-center">
                {{ filtersAppliedCount }} {{ $t('views.products.filtersApplied') }}
                <v-btn
                    @click="clearFilters()"
                    variant="text"
                    class="tw-ml-auto tw-underline"
                    >{{ $t('views.products.clearAll') }}</v-btn
                >
            </p>
            <!-------------------PREÇO------------------------>
            <v-range-slider
                v-model="query.priceRange"
                :max="maxPrice"
                :min="minPrice"
                :step="1"
                thumb-label="always"
                hide-details
                class="tw-mt-8">
            </v-range-slider>

            <!-------------------QUANTIDADE------------------------>
            <v-text-field
                label="Quant."
                v-model="query.quantity"
                :hide-spin-buttons="true"
                type="number"
                class="quantity-input">
                <template #append>
                    <v-select
                        @click.stop
                        :items="quantityTypesOptions"></v-select>
                </template>
            </v-text-field>
            <!--NAO HA DADOS-->

            <!-------------------LOCALIZAÇÃO------------------------>
            <!--NAO HA DADOS-->
            <v-autocomplete
                :label="$t('views.products.location')"
                clearable
                v-model="query.location"
                class=""></v-autocomplete>

            <!-------------------TIPO------------------------>

            <v-select
                :items="productTypeOptions"
                :label="$t('views.products.labelType')"
                clearable
                :placeholder="$t('views.products.placeholder.Type')"
                v-model="query.productType"
                hide-details
                class=""></v-select>

            <!-------------------IS FINISHED------------------------>

            <v-select
                v-model="query.isFinished"
                :items="options.yesNoOptions()"
                item-title="text"
                item-value="value"
                :label="$t('views.products.labelIsFinished')"
                clearable
                :placeholder="$t('views.products.placeholderSelectOption')"
                hide-details
                class=""></v-select>

            <!-------------------MATTER STATE------------------------>
            <v-select
                v-model="query.matterState"
                :items="matterStateOptions"
                :label="$t('views.products.labelMatterState')"
                clearable
                :placeholder="$t('views.products.placeholderMatterState')"
                hide-details
                class=""></v-select>

            <!-------------------COLOR------------------------>
            <v-autocomplete
                v-model="query.color"
                :items="colorOptions"
                :label="$t('views.products.labelColor')"
                clearable
                :placeholder="$t('views.products.placeholderSelectColor')"
                hide-details
                class=""></v-autocomplete>

            <!-------------------SEASON------------------------>
            <v-autocomplete
                v-model="query.season"
                :items="seasonOptions"
                :label="$t('views.products.labelSeason')"
                clearable
                :placeholder="$t('views.products.placeholderSeasonSelect')"
                hide-details
                class=""></v-autocomplete>

            <!-------------------SHORT CIRCUIT------------------------>
            <v-select
                v-model="query.shortCircuit"
                :items="options.yesNoOptions(false)"
                item-title="text"
                item-value="value"
                clearable
                :label="$t('views.products.labelShortCircuits')"
                :placeholder="$t('views.products.placeholderSelectOption')"
                hide-details
                class=""></v-select>

            <!-------------------NATURE------------------------>
            <v-select
                v-model="query.nature"
                :items="natureOptions"
                :label="$t('views.products.labelNature')"
                clearable
                :placeholder="$t('views.products.placeholderSelectNature')"
                hide-details
                class=""></v-select>
        </div>
    </v-navigation-drawer>
</template>
<script setup lang="ts">
    import { useOptions } from '@/composables/useOptions';
    //@ts-ignore
    import { Product_QuantityType, Product_MatterState, Product_Nature, Product_Type, Product_Color, Product_Season } from '@/contentTypes.d.ts';
    import { watch } from 'vue';
    import { ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import _ from 'lodash';

    const isOpen = defineModel<boolean>();

    const $router = useRouter();
    const $route = useRoute();
    const options = useOptions();

    const query = ref({
        priceRange: [0, 20],
        quantity: undefined,
        location: undefined,
        productType: undefined,
        isFinished: undefined,
        matterState: undefined,
        color: undefined,
        season: undefined,
        shortCircuit: undefined,
        nature: undefined,
    });

    const maxPrice = ref(220);
    const minPrice = ref(0);

    const quantityTypesOptions = Object.values(Product_QuantityType);
    const matterStateOptions = Object.values(Product_MatterState);
    const natureOptions = Object.values(Product_Nature);
    const colorOptions = Object.values(Product_Color);
    const productTypeOptions = Object.values(Product_Type);
    const seasonOptions = Object.values(Product_Season);

    const filtersAppliedCount = ref(0);

    watch(
        query,
        (newQuery) => {
            const currentQuery = _.cloneDeep($route.query);
            const query = _.cloneDeep(newQuery);
            // remove null and undefined values
            for (const key in query) {
                console.log(query[key as keyof typeof query]);

                if (query[key as keyof typeof query] === null) {
                    //@ts-ignore
                    query[key] = undefined;
                }
            }
            // set the query in the router
            $router.push({
                query:
                    // join the current query with the new query
                    { ...currentQuery, ...query },
            });
        },
        { deep: true },
    );

    function init() {
        const currentQuery = $route.query;
        if (currentQuery) {
            for (const key in currentQuery) {
                if (key in query.value) {
                    //@ts-ignore
                    query.value[key] = currentQuery[key];
                }
            }
        }
    }

    init();

    function clearFilters() {
        //reset filters
        query.value = {
            priceRange: undefined,
            quantity: undefined,
            location: undefined,
            productType: undefined,
            isFinished: undefined,
            matterState: undefined,
            color: undefined,
            season: undefined,
            shortCircuit: undefined,
            nature: undefined,
        };
        // then remove the query from the router
        $router.push({ query: {} });
    }

    // count the number of filters applied
    watch(
        query,
        () => {
            let count = 0;
            for (const key in query.value) {
                if (query.value[key as keyof typeof query.value]) {
                    count++;
                }
            }
            filtersAppliedCount.value = count;
        },
        { deep: true },
    );
</script>
