<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script setup lang="ts">
    import { useAuth } from '@/composables/useAuth';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from './store/auth';

    const { locale } = useI18n();
    const authStore = useAuthStore();

    locale.value = authStore.getLocale;

    useAuth().checkAuth();
</script>
