<template>
    <div class="tw-flex tw-flex-col tw-gap-3">
        <div class="tw-relative">
            <io-img
                :aspect-ratio="1"
                contain
                class="tw-w-full tw-rounded-xl tw-border tw-border-gray-300"
                :media="firstPicture"
                content-type="ShippingCompany"
                format="medium">
                <div v-if="authStore.isAuthenticated">
                    <v-btn
                        @click="toggleFavorite"
                        position="absolute"
                        class="tw-right-3 tw-top-3"
                        :style="{ background: 'rgba(255, 255, 255, 0.8)' }"
                        icon
                        size="3.5rem">
                        <v-icon
                            :color="isFavorite ? 'secondary' : 'green-900'"
                            size="2.5rem">
                            {{ isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                        </v-icon>
                    </v-btn>
                </div>
            </io-img>
        </div>

        <div class="tw-flex tw-justify-between max-md:tw-flex max-md:tw-flex-col lg:tw-items-center">
            <h4 class="tw-text-xs xl:tw-text-2xl">
                {{ partner.name }}
            </h4>
            <v-btn
                :to="{ name: 'Partner', params: { id: partner.id } }"
                class="tw-bg-primary tw-text-green-900 max-2xl:tw-text-xs"
                :size="xlAndUp ? 'default' : 'x-small'">
                {{ $t('shared.seeDetails') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { PropType, ref, defineProps } from 'vue';
    import { useDisplay } from 'vuetify';
    import { Media, Partner } from '../../contentTypes';

    import ioImg from '@/components/ioImg.vue';
    import { useFavorites } from '@/composables/useFavorites';
    import { useAuthStore } from '@/store/auth';

    const props = defineProps({
        partner: {
            type: Object as PropType<Partner>,
            required: true,
        },
    });

    const authStore = useAuthStore();
    const { xlAndUp } = useDisplay();
    const $favorites = useFavorites();
    const favoriteId = ref<number | null | undefined>(props.partner.favoriteId ?? null);

    const firstPicture = (props.partner?.picture as Media) || undefined;

    const isFavorite = ref(!!props.partner?.favoriteId);

    const toggleFavorite = async (partnerId?: number) => {
        try {
            if (partnerId === undefined) {
                partnerId = props.partner.id;
            }

            if (!isFavorite.value) {
                const { data } = await $favorites.addFavorite('partner', String(props.partner.id));
                favoriteId.value = data.data.id;
                isFavorite.value = true;
            } else {
                await $favorites.removeFavorite(favoriteId.value as number);
                favoriteId.value = undefined;
                isFavorite.value = false;
            }
        } catch (error) {
            console.error('Error toggling favorite:', error);
        }
    };
</script>

<style scoped></style>
