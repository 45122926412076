<template>
    <div>
        <v-row>
            <v-col>
                <label
                    class="text-dark_green text-md-h6 md:tw-font-medium"
                    for="nipc"
                    >NIPC</label
                >
                <v-text-field
                    class="mt-2"
                    hide-details="auto"
                    v-model="nif"
                    name="nipc"
                    variant="outlined"
                    density="default"
                    placeholder="NIPC"
                    @input="checkNif"></v-text-field>
            </v-col>
        </v-row>

        <v-row v-if="nifExistente">
            <v-col>
                <label for="nome">Nome</label>
                <v-text-field
                    v-model="nome"
                    name="nome"
                    placeholder="Nome"
                    :readonly="true"></v-text-field>
            </v-col>
            <v-col>
                <label for="dataCriacao">Data de Criação</label>
                <v-text-field
                    v-model="dataCriacao"
                    name="dataCriacao"
                    placeholder="Data de Criação"
                    :readonly="true"></v-text-field>
            </v-col>
        </v-row>

        <v-row v-if="nifExistente"> 
      <v-col>
        <label for="email">Email</label>
        <v-text-field v-model="email" name="email" placeholder="Email"></v-text-field> 
      </v-col>
    </v-row>

    <v-row v-if="nifExistente"> 
      <v-col>
        <label for="password">Password</label>
        <v-text-field v-model="password" name="password" placeholder="Password" type="password"></v-text-field> 
      </v-col>
    </v-row>

    <v-row v-if="nifExistente"> 
      <v-col>
        <label for="confirmPassword">Confirmar Password</label>
        <v-text-field v-model="confirmPassword" name="confirmPassword" placeholder="Confirmar Password" type="password"></v-text-field> 
      </v-col>
    </v-row>

    

    <v-row v-if="!nifExistente && nif !== ''"> 
      <v-col>
        <span class="text-red">Este NIF não existe. Por favor, registre-se no apa.</span>
      </v-col>
    </v-row>

    <v-row v-if="nifExistente"> 
      <v-col>
        <v-btn class="mt-2" @click.prevent="registerAccount">Registar Conta</v-btn>
      </v-col>
    </v-row>

    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import axios from 'axios';

    const nif = ref('');
    const nome = ref('');
    const dataCriacao = ref('');
    const nifExistente = ref(false);
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');

    const checkNif = async () => {
        try {
            if (nif.value !== '') {
                const response = await axios.get(`https://api.beat.iotechpis.com/api/auth/local/register/getTransporterByNif?nif=${encodeURIComponent(nif.value)}`);
                if (response.data && response.data.nif === nif.value) {
                    nome.value = response.data.nome;
                    dataCriacao.value = response.data.dateCreate;
                    nifExistente.value = true;
                } else {
                    nifExistente.value = false;
                }
            }
        } catch (error) {
            console.error('Erro ao verificar o NIF:', error);
            nifExistente.value = false;
        }
    };

    const registerAccount = async () => {
        if (nifExistente.value && email.value !== '' && password.value !== '' && password.value === confirmPassword.value) {
            const accountData = {
                data: {
                    email: email.value,
                    password: password.value,
                    role: 5,
                },
            };

            try {
                const response = await axios.post('https://api.beat.iotechpis.com/api/users/', accountData);
                console.log('Conta criada com sucesso:', response.data);
            } catch (error) {
                console.error('Erro ao criar conta:', error);
            }
        } else {
            console.error('Por favor, preencha todos os campos corretamente.');
        }
    };
</script>
