<template>
    <div class="first-section tw-flex tw-flex-col xl:tw-flex-row">
        <div class="tw-order-2 tw-flex tw-grow tw-flex-col tw-gap-8 tw-bg-green-900 tw-p-6 tw-py-12  xl:tw-w-1/2 xl:tw-p-20">
            <div class="tw-flex tw-items-center tw-gap-16 max-xl:tw-justify-center">
                <v-img
                    class="tw-max-w-[130px] lg:tw-max-w-[220px]"
                    src="@/assets/images/logo-pink.svg"></v-img>
                <v-img
                    class="tw-max-w-[130px] lg:tw-max-w-[220px]"
                    src="@/assets/images/footer/citeve.svg"></v-img>
            </div>
            <h1 class="tw-text-3xl tw-font-medium tw-text-primary max-xl:tw-text-center lg:tw-text-4xl xl:tw-mt-20 xl:tw-text-6xl">
                {{ $t('home.title') }}
            </h1>

            <p class="min-md:tw-text-left tw-mt-0 tw-mx-auto tw-max-w-2xl tw-text-xl tw-font-light tw-text-white max-md:tw-text-center xl:tw-text-2xl">
                {{ $t('home.text') }}
            </p>
        </div>
        <v-img
            class="tw-order-1 max-xl:tw-h-[30dvh] xl:tw-order-3 xl:tw-w-1/2"
            cover
            src="@/assets/images/backgrounds/secondary.jpg"></v-img>
    </div>

    <div class="tw-grid tw-grid-cols-1 tw-gap-10 tw-px-12 tw-px-24 tw-py-[var(--padding-top)] tw-text-center xl:tw-grid-cols-4">
        <div class="tw-border-t-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-border-gray tw-bg-white tw-py-4 xl:tw-gap-2 xl:tw-border-2 xl:tw-py-12">
            <v-icon class="tw-text-7xl tw-text-secondary xl:tw-text-7xl">mdi-heart</v-icon>
            <p class="tw-text-3xl tw-font-medium tw-text-green-900 xl:tw-text-3xl">59023</p>
            <p class="tw-text-xl tw-font-medium tw-text-green-900 xl:tw-text-2xl">{{ $t('home.transactions') }}</p>
        </div>
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-border-t-2 tw-border-gray tw-bg-white tw-py-4 xl:tw-gap-2 xl:tw-border-2 xl:tw-border-2 xl:tw-py-12">
            <v-icon class="tw-text-7xl tw-text-secondary xl:tw-text-7xl">mdi-heart</v-icon>
            <p class="tw-text-3xl tw-font-medium tw-text-green-900 xl:tw-text-3xl">485</p>
            <p class="tw-text-xl tw-font-medium tw-text-green-900 xl:tw-text-2xl">{{ $t('home.entities') }}</p>
        </div>
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-border-t-2 tw-border-gray tw-bg-white tw-py-4 xl:tw-gap-2 xl:tw-border-2 xl:tw-border-2 xl:tw-py-12">
            <v-icon class="tw-text-7xl tw-text-secondary xl:tw-text-7xl">mdi-heart</v-icon>
            <p class="tw-text-3xl tw-font-medium tw-text-green-900 xl:tw-text-3xl">45</p>
            <p class="tw-text-xl tw-font-medium tw-text-green-900 xl:tw-text-2xl">{{ $t('home.circuits') }}</p>
        </div>

        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-border-2 tw-border-gray tw-bg-white tw-py-4 xl:tw-gap-2 xl:tw-py-12">
            <v-icon class="tw-text-7xl tw-text-secondary xl:tw-text-7xl">mdi-heart</v-icon>
            <p class="tw-text-3xl tw-font-medium tw-text-green-900 xl:tw-text-3xl">20570</p>
            <p class="tw-text-xl tw-font-medium tw-text-green-900 xl:tw-text-2xl">{{ $t('home.products') }}</p>
        </div>
    </div>
    <div class="tw-bg-green-900 tw-pt-16 tw-h-96">
        <div class="tw-items-center tw-justify-between tw-px-[var(--padding-right)] tw-h-96">
            <h1 class="tw-text-3xl tw-font-medium tw-text-white">Novas descobertas</h1>

            <v-carousel hide-delimiters class="tw-h-72">
                <v-carousel-item>
                    <div class="tw-flex tw-pt-5">
                        <img
                            class="tw-mr-8"
                            src="@/assets/images/backgrounds/primary.jpg"
                            alt="Imagem de fundo"
                            width="30%" />
                        <div class="tw-text-right">
                            <p class="tw-pr-[var(--padding-right)] tw-text-2xl tw-leading-relaxed tw-text-white">
                                Com o mote da bioeconomia, o be@t reúne 54 entidades em busca de soluções para a reconversão dos setores têxtil e do vestuário em temas como a rastreabilidade e a
                                transparência, o ecodesign e a ecoengenharia, o impacto e as novas métricas de sustentabilidade.
                            </p>
                        </div>
                    </div>
                </v-carousel-item>

                <v-carousel-item>
                    <p class="tw-text-medium">Segundo slide.</p>
                </v-carousel-item>

                <v-carousel-item>
                    <p class="tw-text-medium">Este é o terceiro slide.</p>
                </v-carousel-item>
            </v-carousel>
        </div>
    </div>
    <div class="tw-pt-[var(--padding-top)]">
        <div class="tw-flex tw-items-center tw-justify-between tw-px-[var(--padding-right)]">
            <h4 class="tw-text-xl tw-font-bold tw-text-green-900 xl:tw-text-4xl">{{ $t('home.newProducts') }}</h4>
            <v-btn
                :to="{ name: 'Marketplace' }"
                color="secondary"
                :size="lgAndUp ? 'x-large' : 'default'"
                >{{ $t('shared.seeAll') }}</v-btn
            >
        </div>
        <List :query="querySuggestions" />
    </div>
    <div class="tw-flex tw-flex-row tw-border-t-2 tw-border-green-900 xl:tw-flex-row">
        <div class="tw-relative tw-h-72 tw-w-full xl:tw-h-auto xl:tw-w-3/5">
            <v-img
                width="100%"
                height="100%"
                cover
                class="tw-absolute"
                src="@/assets/images/backgrounds/primary.jpg"></v-img>
        </div>

        <div class="tw-w-full tw-border-b-2 tw-border-l-2 tw-border-green-900 tw-px-6 tw-py-12 tw-text-center lg:tw-text-left xl:tw-w-2/5 xl:tw-border-b-0 xl:tw-border-l-2 xl:tw-px-16 xl:tw-py-24">
            <h4 class="tw-mb-6 tw-text-4xl tw-font-bold tw-text-green-900">{{ $t('home.questions') }}</h4>
            <v-btn
                :to="{ name: 'Contact' }"
                size="x-large"
                color="secondary"
                >{{ $t('home.contactUs') }}</v-btn
            >
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useDisplay } from 'vuetify';
    import { computed } from 'vue';
    import List from './Products/List.vue';

    const { lgAndUp } = useDisplay();

    const querySuggestions = computed(() => ({
        pagination: {
            pageSize: 4,
        },
        sort: ['createdAt:desc'],
    }));
</script>

<style scoped>
    .first-section {
        @apply xl:tw-h-[calc(100dvh-100px)];
    }
</style>
