import { colord } from 'colord';

const colors = createVariations({
    transparent: '#FFFFFF00',
    current: 'currentColor',
    white: '#FFFFFF',
    black: '#000000',
    dark: '#292839',
    red: '#FF0000',
    yellow: '#FFFF00',
    primary: {
        200: '#E1F8AC',
        DEFAULT: '#C2FF58',
    },
    secondary: '#B18CFF',
    pink: {
        DEFAULT: '#F9F3EF',
        600: '#F8EFE8',
        800: '#F6DCCA',
    },
    gold: '#E0CA35',
    orange: {
        300: '#FFBC00',
        DEFAULT: '#EF8534',
    },
    green: {
        100: '#ECE9E5',
        DEFAULT: '#8FE828',
        900: '#284940',
    },
    gray: {
        300: '#C8C8C8',
        DEFAULT: '#707070',
    },
});

export const theme = {
    colors,
    fontFamily: {
        sans: ['Aeonik', 'sans-serif'],
    },
} as any;

export default theme;

function createVariations(colors: any) {
    const variations: any = {};
    for (const color in colors) {
        if (['transparent', 'current', 'black', 'white'].includes(color)) {
            variations[color] = colors[color];
            continue;
        }
        if (typeof colors[color] === 'object') {
            for (const shade in colors[color]) {
                if (shade.toUpperCase() != 'DEFAULT') variations[`${color}-${shade}`] = colors[color][shade];
                else variations[color] = colors[color][shade];
            }
            continue;
        }

        variations[color] = colors[color];
        const col = colord(colors[color]);
        for (let i = 0.05; i <= 0.2; i += 0.05) {
            variations[`${color}-${Math.abs(Math.round(i * 2000 - 500))}`] = col.lighten(i).toHex();
        }
        for (let i = 0.05; i <= 0.2; i += 0.05) {
            variations[`${color}-${Math.abs(Math.round(i * 2000 + 500))}`] = col.darken(i).toHex();
        }
    }
    return variations;
}

export function vuetifyColors() {
    const vuetifyColors: any = {};
    for (const color in theme.colors) {
        if (['transparent', 'current'].includes(color)) continue;
        vuetifyColors[color] = theme.colors[color];
    }
    return vuetifyColors;
}

export function tailwindColors() {
    const tailwindColors: any = {};
    for (const color in theme.colors) {
        if (['transparent', 'current'].includes(color)) {
            tailwindColors[color] = theme.colors[color];
            continue;
        }
        tailwindColors[color] = `rgba(var(--v-theme-${color}), <alpha-value>)`;
    }

    return tailwindColors;
}
