<template>
    <main class="tw-flex tw-flex-col sm:tw-flex-row tw-h-full tw-flex-grow tw-flex-shrink-0">
        <div
            class="tw-w-full tw-flex-grow tw-z-10 tw-p-4 sm:tw-max-w-lg xl:tw-max-w-3xl tw-bg-pink tw-shrink-0 tw-flex tw-flex-col tw-justify-evenly sm:tw-justify-center tw-items-center tw-gap-8 md:tw-gap-12 tw-order-3 sm:tw-order-1 tw-py-10">
            <div class="tw-w-full tw-px-4 tw-flex tw-items-center tw-justify-center md:tw-hidden sm:tw-hidden ">
                <v-img
                    src="/logo.svg"
                    width="100%"
                    max-width="10rem"></v-img>
            </div>
            <router-view />
        </div>
        <div
            id="login-background"
            class="sm:tw-grow tw-max-h-[11.5rem] tw-h-full sm:tw-max-h-none tw-z-0 tw-order-2"></div>
    </main>
</template>

<script setup lang="ts"></script>

<style scoped>
    #login-background {
        background-image: url('@/assets/images/auth/login.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        /* transform: translateX(-50%); */
        margin-left: -2.5rem;
    }
</style>
