<template>
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4">
        <div class="tw-text-center">
            <h1 class="tw-mb-3 tw-text-xl tw-font-bold sm:tw-text-2xl">{{ $t('auth.welcomeBack') }}</h1>
            <h4 class="tw-max-w-xs tw-text-lg sm:tw-max-w-md">{{ $t('auth.welcomeBackText') }}</h4>
        </div>
        <v-form
            ref="form"
            @submit.prevent="onSubmit"
            class="auth-form">
            <div>
                <v-label class="tw-mb-2 tw-text-lg sm:tw-text-xl">{{ $t('fields.email') }}</v-label>
                <v-text-field
                    v-model.trim="email"
                    class="auth-input"
                    :rules="[rules.required, rules.email]"
                    type="email"
                    name="email"></v-text-field>
            </div>
            <div>
                <v-label class="tw-mb-2 tw-text-lg sm:tw-text-xl">{{ $t('fields.password') }}</v-label>
                <v-text-field
                    v-model="password"
                    class="auth-input"
                    name="current-password"
                    :rules="[rules.required]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>
            </div>

            <div v-if="errorMessage">
                <v-alert
                    type="error"
                    variant="tonal"
                    density="compact"
                    >{{ errorMessage }}</v-alert
                >
            </div>
            <router-link
                class="tw-my-2 tw-text-right tw-text-sm tw-underline sm:tw-my-5 sm:tw-text-base"
                :to="{ name: 'ForgotPassword' }">
                {{ $t('auth.forgotPassword') }}
            </router-link>
            <!-- <div class="tw-text-center">
                <v-btn
                    :loading="isLoading"
                    type="submit"
                    size="x-large"
                    color="primary">
                    {{ $t('auth.login') }}
                </v-btn>
            </div> -->
            <!-- Temporary code for demo purposes -->
            <div class="tw-mx-auto tw-flex tw-gap-x-8">
                <v-btn
                    :loading="isLoading"
                    type="submit"
                    size="x-large"
                    color="primary"
                    @click="loginClient()">
                    Demo Cliente
                </v-btn>
                <v-btn
                    :loading="isLoading"
                    type="submit"
                    size="x-large"
                    color="primary"
                    @click="loginSupplier()">
                    Demo Fornecedor
                </v-btn>
            </div>
        </v-form>
    </div>

    <router-link
        class="tw-text-center tw-text-sm sm:tw-mt-5 sm:tw-text-base"
        :to="{ name: 'Register' }">
        {{ $t('auth.noAccount') }} <span class="tw-font-bold tw-underline"> {{ $t('auth.registerHere') }}</span>
    </router-link>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import _ from 'lodash';
    import slugify from 'slugify';
    import { useI18n } from 'vue-i18n';
    import qs from 'qs';

    const authStore = useAuthStore();
    const $router = useRouter();
    const $t = useI18n().t;

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);
    const isPasswordVisible = ref(false);

    const email = ref('demo@demo.com');
    const password = ref('12345678');
    const errorMessage = ref('');

    async function onSubmit() {
        if (!form.value) return;

        errorMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            let query = qs.stringify({
                populate: ['picture'],
            });

            const { data } = await axios.post(`/auth/local?${query}`, {
                identifier: email.value,
                password: password.value,
            });

            authStore.setToken(data.jwt);
            authStore.setUser(data.user);

            $router.push('/');
        } catch (error) {
            let messageKey = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
                replacement: '_',
                trim: true,
                lower: true,
            });
            errorMessage.value = $t(`auth.errors.${messageKey}`);
        } finally {
            isLoading.value = false;
        }
    }

    async function loginClient() {
        email.value = 'democliente@demo.com';
        password.value = '12345678';
        await onSubmit();
    }

    async function loginSupplier() {
        email.value = 'demofornecedor@demo.com';
        password.value = '12345678';
        await onSubmit();
    }
</script>

<style></style>
