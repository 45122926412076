<template>
    <div class="tw-flex tw-flex-col tw-gap-8 lg:tw-px-[var(--padding-left)] lg:tw-pb-[var(--padding-bottom)] max-lg:tw-px-[var(--padding-left)] max-lg:tw-py-[var(--padding-top)]">
<template v-if="smAndUp || (!smAndUp && $route.name == 'Profile')">
    <p class="lg:tw-text-left tw-flex max-lg:tw-justify-center tw-h-12 tw-items-center max-lg:tw-text-center tw-text-green-900 xl:tw-mb-4 lg:tw-text-5xl max-lg:tw-text-2xl">{{ $t('views.orders.orders') }}</p>
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center sm:tw-hidden ">
        <v-img
            :aspect-ratio="1"
            contain
            class="tw-w-full tw-max-w-[13.75rem] tw-rounded-4xl"
            src="https://picsum.photos/600/600"></v-img>
        <h2 class="tw-mt-4 tw-text-xl">{{ $t('views.profile.usarName') }}</h2>
    </div>
    <div class=" profile tw-grid tw-grid-cols-1 tw-gap-8 sm:tw-grid-cols-2 sm:tw-gap-4 lg:tw-grid-cols-5  ">
        <v-btn
            class="tw-col-span-1 tw-flex tw-w-full tw-border-2 tw-text-center max-sm:tw-mx-auto max-sm:tw-max-w-xs max-sm:tw-justify-start sm:tw-grow"
            v-for="route in routes"
            :active="false"
            :to="{ name: route.route }"
            :key="route.route"
            :text="route.label"
            :variant="getVariant(route.route)"
            :size="buttonSize"
            color="green-900">
            <template
                v-if="!smAndUp"
                #prepend>
                <v-icon
                    size="32"
                    class="tw-mr-1"
                    >{{ route.icon }}</v-icon
                >
            </template>

            <template
                v-if="!smAndUp"
                #append>

                <img
                    class="tw-ml-auto tw-h-6 tw-w-auto "
                    src="@/assets/icons/chevron-right.svg"
                    alt="" />
            </template>
        </v-btn>
    </div>
</template>
<router-view />
</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useDisplay } from 'vuetify';

const $route = useRoute();
const { lgAndUp, xlAndUp, smAndUp } = useDisplay();

const routes = [

    {
        label: 'Histórico',
        route: 'OrdersHistory',
        icon: 'mdi-history',
    },
    {
        label: 'Encomendas ativas',
        route: 'ActiveOrders',
        icon: 'mdi-truck-delivery',
    },


];

const currentRoute = computed(() => $route.name);

const buttonSize = computed(() => {
    if (xlAndUp.value) {
        return 'x-large';
    }

    return 'large';
});

function getVariant(route: string) {
    if (currentRoute.value == route && smAndUp.value) {
        return 'flat';
    }

    return 'outlined';
}
</script>

<style>
.profile {
    a.v-btn {
        @apply tw-h-auto tw-py-[1em];
    }
    a.v-btn .v-btn__append {
        @apply tw-ml-auto;
    }
}
</style>
