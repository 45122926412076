<template>
<div  class="tw-px-[var(--padding-left)] tw-py-[var(--padding-top)]">
  <p class="tw-text-center tw-text-green-900 max-lg:tw-text-2xl lg:tw-text-5xl xl:tw-mb-4">Menu de Encomendas Pendentes</p>

  
      <div class="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2 2xl:tw-gap-16">
        <CardVendorSide
          v-for="order in filteredOrders"
          :key="order.id"
          :order="order"
        />
      </div>
</div>
    
      
    
  </template>
  
  <script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import CardVendorSide from './CardVendorSide.vue';
  
    const filteredOrders = ref<{ id: string, status: string }[]>([]);

  
    const fetchOrders = async () => {
    try {
      const response = await axios.get('https://api.beat.iotechpis.com/api/orders/?populate=*');
      if (Array.isArray(response.data)) {
        filteredOrders.value = response.data.filter((order: any) => order.status === 'pending');
      } else if (response.data && Array.isArray(response.data.data)) {
        filteredOrders.value = response.data.data.filter((order: any) => order.status === 'pending');
      } else {
        console.error('Unexpected API response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  
    onMounted(fetchOrders);
  </script>
  
  <style scoped>
    /* Add your scoped styles here if needed */
  </style>
  