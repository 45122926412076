import { useAuthStore } from '@/store/auth';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import { APIResponseSingle } from '../contentTypes';

export function useFavorites() {
    const authStore = useAuthStore();
    return {
        addFavorite: async (type: string, id: string): AxiosPromise<APIResponseSingle<'Favorite'>> => {
            return axios.post(`/favorites`, {
                data: {
                    type: type,
                    [type]: id,
                    user: authStore.getUser?.id,
                },
            });
        },
        removeFavorite: async (id: number) => {
            return axios.delete(`/favorites/${id}`);
        },
    };
}
