<template>
    <v-bottom-navigation
        bg-color="primary"
        elevation="0"
        :model-value="selected"
        mandatory
        height="60">
        <v-btn
            v-for="route in routes"
            :key="route.route"
            :value="route.route"
            :icon="route.icon"
            :to="{ name: route.route }"
            :class="route.class || ''">
        </v-btn>
    </v-bottom-navigation>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { ref } from 'vue';
    import { useRoute } from 'vue-router';

    const $route = useRoute();
    const selected = computed(() => $route.name);
    const routes = [
        {
            route: 'Suppliers',
            icon: 'mdi-store',
        },
        {
            route: 'Shippings',
            icon: 'mdi-truck',
        },
        {
            // está comentado porque não temos a rota
            // route: 'Search',
            route: 'Marketplace',
            icon: 'mdi-magnify',
            class: 'search-btn',
        },
        {
            // route: 'Notifications',
            route: 'Partners',
            icon: 'mdi-package-variant-closed',
        },
        {
            route: 'Profile',
            icon: 'mdi-account',
        },
    ];
</script>

<style>
    .v-bottom-navigation {
        max-width: 25rem !important;
        width: 90% !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        bottom: 1rem !important;
        overflow: unset !important;

        a {
            min-width: 0 !important;
            border-radius: 0.5rem !important;
            height: 2.5rem !important;
            width: 2.5rem !important;
            font-size: 0.8rem !important;
            color: rgb(var(--v-theme-green-900)) !important;
        }

        .search-btn {
            background-color: rgb(var(--v-theme-green-900)) !important;
            color: rgb(var(--v-theme-pink)) !important;
            border: 3px solid rgb(var(--v-theme-pink)) !important;
            height: 3.5rem !important;
            width: 3.5rem !important;
            font-size: 1rem !important;
            transform: translateY(-2rem);
            border-radius: 50% !important;
        }

        .v-btn--selected {
            background-color: rgb(var(--v-theme-green-900)) !important;
            color: rgb(var(--v-theme-primary)) !important;
        }

        .v-btn__overlay {
            display: none !important;
        }

        .v-bottom-navigation__content {
            justify-content: space-evenly !important;
            align-items: center !important;
        }
    }
</style>
