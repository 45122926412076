<template>
    <Navbar v-if="showNavbar" />
    <v-main class="tw-bg-pink">
        <div
            v-if="showBreadcrumbs"
            class="tw-px-[var(--padding-left)] tw-pt-[var(--padding-top)]">
            <Breadcrumbs />
        </div>
        <Suspense>
            <router-view />
        </Suspense>
    </v-main>
    <Footer v-if="showFooter" />
    <Bottombar v-if="showBottombar" />
</template>

<script lang="ts" setup>
    import Footer from './Layout/Footer.vue';
    import Navbar from './Layout/Navbar.vue';
    import Bottombar from './Layout/Bottombar.vue';
    import Breadcrumbs from './Layout/Breadcrumbs.vue';
    import { useDisplay } from 'vuetify';
    import _ from 'lodash';
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    const { lgAndUp } = useDisplay();
    const $route = useRoute();

    const showNavbar = computed(() => {
        const route = $route.matched.find((route) => _.has(route.meta, 'navbar'));
        if (route && route.meta?.navbar !== undefined) return route.meta.navbar;

        return lgAndUp.value;
    });

    const showFooter = computed(() => {
        const route = $route.matched.find((route) => _.has(route.meta, 'footer'));
        if (route && route.meta?.footer !== undefined) return route.meta.footer;

        return lgAndUp.value;
    });

    const showBottombar = computed(() => {
        const route = $route.matched.find((route) => _.has(route.meta, 'bottombar'));
        if (route && route.meta?.bottombar !== undefined) return route.meta.bottombar;

        return !lgAndUp.value;
    });

    const showBreadcrumbs = computed(() => {
        const route = $route.matched.find((route) => _.has(route.meta, 'breadcrumbs'));
        if (route && route.meta?.breadcrumbs !== undefined) return route.meta.breadcrumbs;

        return lgAndUp.value;
    });

    const bottombarHeight = computed(() => {
        if (!showBottombar.value || $route.query.view == 'map') return 0;
        return '104px';
    });
</script>

<style>
    main.v-main {
        --v-layout-bottom: v-bind(bottombarHeight) !important;

        /* first child */
        /* > *:first-child {
            padding-top: var(--padding-top) !important;
        } */

        /* last child */
        /* > *:last-child {
            padding-bottom: var(--padding-bottom) !important;
        } */
    }
</style>
