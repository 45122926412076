<template>
    <div>
        <v-btn @click="openDialog">Adicionar Produto</v-btn>

        <v-dialog
            v-model="dialog"
            width="auto"
            height="auto"
            scrollable
            max-width="75% ">
            <v-card class="tw-w-screen tw-p-8">
                <div class="tw-flex tw-flex-row">
                    <v-card-title class="tw-pl-6 tw-pt-4">Adicionar Novo Produto</v-card-title>
                    <v-card-actions>
                        <v-btn
                            color="black"
                            variant="flat"
                            @click="closeDialog"
                            >Fechar</v-btn
                        >
                    </v-card-actions>
                </div>
                <v-card-text>
                    <v-container class="tw-px-0 tw-pt-0">
                        <v-stepper
                            :items="['', '', '']"
                            @update:model-value="onStepChange">
                            <template v-slot:item.1>
                                <v-card flat>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <!-- Foto quadrada do produto -->

                                                <v-file-input
                                                    :label="$t('views.products.form.picture')"
                                                    accept="image/*"
                                                    class="tw-pb-2"
                                                    v-model="product.pictures"
                                                    required></v-file-input>

                                                <!-- Nome do Produto e EWC Code -->
                                                <div class="tw-flex-grid tw-flex">
                                                    <v-text-field
                                                        class="tw-pb-4"
                                                        v-model="product.name"
                                                        :label="$t('views.products.form.name')"
                                                        required></v-text-field>
                                                    <v-text-field
                                                        class="tw-pb-4"
                                                        v-model="product.ewcCode"
                                                        :label="$t('views.products.form.ewcCode')"
                                                        required></v-text-field>
                                                </div>
                                                <!-- Average Quantity -->
                                                <v-text-field
                                                    class="tw-pb-3"
                                                    v-model="averageQuantity"
                                                    type="number"
                                                    label="Quantidade Média"
                                                    required>
                                                </v-text-field>
                                                <!-- Tabela da sazonalidade entra aqui *********-->
                                                <v-data-table
                                                    class="tw-pb-3"
                                                    :headers="headers"
                                                    :items="desserts"
                                                    hide-default-footer>
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title class="tw-text-base">Sazonalidade</v-toolbar-title>
                                                            <v-divider
                                                                class="mx-4"
                                                                inset
                                                                vertical></v-divider>
                                                            <v-spacer></v-spacer>
                                                            <v-dialog
                                                                v-model="tableDialog"
                                                                max-width="500px">
                                                                <v-card>
                                                                    <v-card-title>
                                                                        <span class="text-h6">Editar</span>
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-container>
                                                                            <v-row>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.jan"
                                                                                        :label="$t('views.products.form.season.january')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.feb"
                                                                                        :label="$t('views.products.form.season.february')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.mar"
                                                                                        :label="$t('views.products.form.season.march')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.apr"
                                                                                        :label="$t('views.products.form.season.april')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.may"
                                                                                        :label="$t('views.products.form.season.may')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.jun"
                                                                                        :label="$t('views.products.form.season.june')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.jul"
                                                                                        :label="$t('views.products.form.season.july')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.aug"
                                                                                        :label="$t('views.products.form.season.august')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.sep"
                                                                                        :label="$t('views.products.form.season.september')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.oct"
                                                                                        :label="$t('views.products.form.season.october')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.nov"
                                                                                        :label="$t('views.products.form.season.november')"></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    md="4"
                                                                                    sm="6">
                                                                                    <v-text-field
                                                                                        type="number"
                                                                                        max="3"
                                                                                        min="0"
                                                                                        v-model="editedItem.dec"
                                                                                        :label="$t('views.products.form.season.december')"></v-text-field>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-container>
                                                                    </v-card-text>

                                                                    <v-card-actions>
                                                                        <v-spacer></v-spacer>
                                                                        <v-btn
                                                                            color="blue-darken-1"
                                                                            variant="text"
                                                                            @click="close">
                                                                            Cancelar
                                                                        </v-btn>
                                                                        <v-btn
                                                                            color="blue-darken-1"
                                                                            variant="text"
                                                                            @click="save">
                                                                            Guardar
                                                                        </v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                        </v-toolbar>
                                                    </template>
                                                    <template #item.jan="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.feb="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.mar="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.apr="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.may="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.jun="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.jul="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.aug="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.sep="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.oct="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.nov="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template #item.dec="{ value }">
                                                        <div
                                                            class="tw-h-3 tw-w-3 tw-rounded-xl"
                                                            :class="{ 'tw-bg-gray': value == 0, 'tw-bg-red': value == 1, 'tw-bg-yellow': value == 2, 'tw-bg-green': value == 3 }"></div>
                                                    </template>
                                                    <template v-slot:item.actions="{ item }">
                                                        <v-icon
                                                            class="me-2"
                                                            size="small"
                                                            @click="editItem(item)">
                                                            mdi-pencil
                                                        </v-icon>
                                                    </template>
                                                </v-data-table>

                                                <!-- Tipo/ Nome de Resíduo -->
                                                <v-select
                                                    v-model="product.type"
                                                    :items="tipoResiduos"
                                                    :label="$t('views.products.form.type')"
                                                    class="tw-pb-2"
                                                    required></v-select>

                                                <!-- Descrição -->
                                                <v-textarea
                                                    class="tw-pt-2"
                                                    v-model="product.description"
                                                    :label="$t('views.products.form.description')"
                                                    required></v-textarea>

                                                <!-- Keywords -->
                                                <div class="tw-flex-grid tw-flex tw-items-center tw-pt-2">
                                                    <label> {{ $t('views.products.form.selectKeyword') }}</label>
                                                    <v-text-field
                                                        v-model="product.keywords"
                                                        :label="$t('views.products.form.keywords')"
                                                        required></v-text-field>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>
                            <template v-slot:item.2>
                                <v-card flat>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <!-- Prazo de Entrega Médio (quantidade e dias)-->

                                                <v-select
                                                    class="tw-pb-3 tw-pt-3"
                                                    v-model="quantidadeTipo"
                                                    :items="quantidadesTipo"
                                                    label="Tipo de Quantidade"
                                                    required>
                                                </v-select>

                                                <v-text-field
                                                    class="tw-pb-3"
                                                    v-model="quantidadeEntrega"
                                                    type="number"
                                                    label="Quantidade de Entrega"
                                                    required>
                                                </v-text-field>

                                                <v-text-field
                                                    class="tw-pb-3 tw-pt-3"
                                                    v-model="prazoEntrega"
                                                    type="number"
                                                    label="Prazo de Entrega Médio (dias)"
                                                    required>
                                                </v-text-field>

                                                <!-- Checkbox: Aplicado ou Armazenamento -->
                                                <label>Este produto vai ser Aplicado ou Armazenamento?</label>
                                                <v-radio-group
                                                    v-model="aplicacaoArmazenamento"
                                                    row
                                                    required>
                                                    <v-radio
                                                        label="Aplicado"
                                                        value="aplicado"></v-radio>
                                                    <v-radio
                                                        label="Armazenamento"
                                                        value="armazenamento"></v-radio>
                                                </v-radio-group>

                                                <!-- Encontra-se armazenado? -->
                                                <label>Encontra-se armazenado?</label>
                                                <v-radio-group
                                                    v-model="estadoArmazenamento"
                                                    row
                                                    required>
                                                    <v-radio
                                                        label="Armazenado"
                                                        value="armazenado"></v-radio>
                                                    <v-radio
                                                        label="Não armazenado"
                                                        value="naoArmazenado"></v-radio>
                                                </v-radio-group>

                                                <!-- Estado/forma resíduo -->
                                                <v-select
                                                    v-model="estadoFormaResiduo"
                                                    :items="estadoFormas"
                                                    class="tw-pb-4"
                                                    label="Estado/forma resíduo"
                                                    required></v-select>

                                                <!-- natureza -->
                                                <v-select
                                                    v-model="natureza"
                                                    :items="naturezaItems"
                                                    class="tw-pb-4"
                                                    label="Natureza"
                                                    required></v-select>

                                                <!-- Cor -->
                                                <v-select
                                                    v-model="cor"
                                                    :items="cores"
                                                    class="tw-pb-4"
                                                    label="Cor"></v-select>

                                                <!-- Condições necessárias de armazenamento -->
                                                <v-textarea
                                                    class="tw-pt-2"
                                                    v-model="condicoesArmazenamento"
                                                    label="Condições Necessárias de Armazenamento"></v-textarea>

                                                <!-- Tempo de armazenamento médio -->
                                                <v-text-field
                                                    class="tw-pt-2"
                                                    v-model="tempoArmazenamentoMedio"
                                                    type="number"
                                                    label="Tempo de Armazenamento Médio"></v-text-field>

                                                <!-- Tempo de armazenamento máximo -->
                                                <v-text-field
                                                    class="tw-pb-2 tw-pt-2"
                                                    v-model="tempoArmazenamentoMaximo"
                                                    type="number"
                                                    label="Tempo de Armazenamento Máximo"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>
                            <template v-slot:item.3>
                                <v-card flat>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <!-- Embalagem -->
                                                <label>Embalagem do Produto</label>
                                                <v-radio-group
                                                    v-model="embalagem"
                                                    row>
                                                    <v-radio
                                                        label="Embalado"
                                                        value="embalado"></v-radio>
                                                    <v-radio
                                                        label="Não Embalado"
                                                        value="naoEmbalado"></v-radio>
                                                    <v-radio
                                                        label="Possibilidade de Embalar"
                                                        value="possibilidadeEmbalar"></v-radio>
                                                </v-radio-group>

                                                <!-- Tipo de embalagem -->
                                                <v-text-field
                                                    class="tw-pb-4"
                                                    v-model="tipoEmbalagem"
                                                    label="Tipo de Embalagem"></v-text-field>

                                                <!-- Toxicidade -->
                                                <label>Toxicidade</label>
                                                <v-radio-group
                                                    v-model="toxicidade"
                                                    row>
                                                    <v-radio
                                                        label="Medida"
                                                        value="medida"></v-radio>
                                                    <v-radio
                                                        label="Não medida"
                                                        value="naoMedida"></v-radio>
                                                </v-radio-group>

                                                <!-- Transporte -->
                                                <label>Transporte</label>
                                                <v-radio-group
                                                    v-model="transporte"
                                                    row>
                                                    <v-radio
                                                        label="A meu cargo"
                                                        value="meuCargo"></v-radio>
                                                    <v-radio
                                                        label="A cargo do comprador"
                                                        value="cargoComprador"></v-radio>
                                                </v-radio-group>

                                                <!-- Sustentabilidade do processo -->
                                                <v-textarea
                                                    class="tw-pt-2"
                                                    v-model="sustentabilidade"
                                                    label="Sustentabilidade do Processo"></v-textarea>

                                                <!-- Localização -->
                                                <v-select
                                                    v-model="selectedLocation"
                                                    :items="localizacoes"
                                                    label="Localização"
                                                    item-text="name"
                                                    item-value="id"
                                                    outlined></v-select>

                                                <div
                                                    id="map"
                                                    class="tw-h-400 tw-w-full"></div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-stepper>
                    </v-container>
                </v-card-text>

                <v-card-actions class="tw-justify-end tw-pb-0 tw-pt-3">
                    <v-btn
                        color="primary"
                        variant="flat"
                        @click="adicionarProduto"
                        >{{ $t('views.products.form.submit') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import { Loader } from '@googlemaps/js-api-loader';
    import { useI18n } from 'vue-i18n';
    import { Product } from '../../contentTypes';
    import { computed, watch } from 'vue';
    import { nextTick } from 'vue';

    const $t = useI18n().t;

    const products = ref<Product[]>([]);

    const product = ref<Product>({} as Product);

    const dialog = ref(false);

    let selectedLocation = ref(null);

    let categorias = ref([]);
    let quantidadesTipo = ref(['kg', 'm', 'unit', 'l']);
    let naturezaItems = ref(['renewable source', 'bio-based content', 'recycled']);
    let tipoResiduos = ref(['textiles', 'other industries', 'textile accessories']);
    let estadoFormas = ref(['liquid', 'solid', 'damp', 'dry']);
    let cores = ref([
        'azul',
        'amarelo',
        'verde',
        'vermelho',
        'castanho',
        'preto',
        'branco',
        'cinzento',
        'laranja',
        'roxo',
        'rosa',
        'bege',
        'prateado',
        'dourado',
        'transparente',
        'multicolorido',
        'outro',
    ]);
    let map: google.maps.Map;
    let marker: google.maps.Marker;
    let markerPosition: google.maps.LatLng | null = null;

    //season table variables
    const tableDialog = ref(false);

    const headers = [
        {
            title: $t('views.products.form.season.january'),
            sortable: false,
            key: 'jan',
        },
        {
            title: $t('views.products.form.season.february'),
            sortable: false,
            key: 'feb',
        },
        {
            title: $t('views.products.form.season.march'),
            sortable: false,
            key: 'mar',
        },
        {
            title: $t('views.products.form.season.april'),
            sortable: false,
            key: 'apr',
        },
        {
            title: $t('views.products.form.season.may'),
            sortable: false,
            key: 'may',
        },
        {
            title: $t('views.products.form.season.june'),
            sortable: false,
            key: 'jun',
        },
        {
            title: $t('views.products.form.season.july'),
            sortable: false,
            key: 'jul',
        },
        {
            title: $t('views.products.form.season.august'),
            sortable: false,
            key: 'aug',
        },
        {
            title: $t('views.products.form.season.september'),
            sortable: false,
            key: 'sep',
        },
        {
            title: $t('views.products.form.season.october'),
            sortable: false,
            key: 'oct',
        },
        {
            title: $t('views.products.form.season.november'),
            sortable: false,
            key: 'nov',
        },
        {
            title: $t('views.products.form.season.december'),
            sortable: false,
            key: 'dec',
        },
        {
            sortable: false,
            key: 'actions',
        },
    ];

    let desserts = ref([]);
    const editedIndex = ref(-1);
    const editedItem = ref({
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
    });
    const defaultItem = {
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
    };

    //computed form title
    const formTitle = computed(() => (editedIndex.value === -1 ? 'New Item' : 'Edit Item'));

    //watch tableDialog
    watch(tableDialog, (val) => {
        val || close();
    });

    function intialize() {
        desserts.value.push({
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
        });
    }

    intialize();

    function editItem(item: any) {
        editedIndex.value = desserts.value.indexOf(item);
        editedItem.value = Object.assign({}, item);
        tableDialog.value = true;
    }

    function close() {
        tableDialog.value = false;
        nextTick(() => {
            editedItem.value = Object.assign({}, defaultItem);
            editedIndex.value = -1;
        });
    }

    function save() {
        if (editedIndex.value > -1) {
            Object.assign(desserts.value[editedIndex.value], editedItem.value);
        } else {
            desserts.value.push(editedItem.value);
        }
        close();
    }

    //handle dialog
    async function openDialog() {
        dialog.value = true;
        fetchLocations();
        fetchCategorias();
    }

    const closeDialog = () => {
        dialog.value = false;
    };

    //fetch categories
    async function fetchCategorias() {
        try {
            const response = await axios.get('https://api.beat.iotechpis.com/api/categories/');
            categorias.value = response.data.data.map((categoria: any) => ({
                text: categoria.name,
                value: categoria.id,
            }));
        } catch (error) {
            console.error('Erro ao carregar categorias:', error);
        }
    }

    //fetch locations
    const fetchLocations = async () => {
        try {
            const response = await fetch('https://api.beat.iotechpis.com/api/locations');
            const data = await response.json();
            console.log('Locations:', data.data);

            // Aqui vamos mapear apenas os nomes das localizações para localizacoes.value
            localizacoes.value = data.data.map((loc: { name: any }) => loc.name);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    //add product
    const adicionarProduto = async () => {
        try {
            if (!markerPosition) {
                console.error('Erro: posição do marcador não está definida.');
                return;
            }

            const novoProduto = {
                name: nome.value,
                sku: keywords.value,
                ewcCode: ewcCode.value,
                description: descricao.value,
                type: tipoResiduo.value || 'textiles',
                matterState: estadoFormaResiduo.value || 'liquid',
                nature: natureza.value || 'recycled',
                color: cor.value || 'castanho',
                storageConditions: condicoesArmazenamento.value || 'sem dados',
                latitude: markerPosition.lat(),
                longitude: markerPosition.lng(),
                quantity: quantidadeEntrega.value,
                quantityType: quantidadeTipo.value,
                deliveryTime: prazoEntrega.value,
                storageTime: tempoArmazenamentoMedio.value,
                location: selectedLocation,

                /* generatedQuantityMonthly
                originInformation
                potentialApplications
                potentialApplications
                acquisitionGoal: aplicacaoArmazenamento.value,
                storage: estadoArmazenamento.value,
    */

                // tempoArmazenamentoMaximo: tempoArmazenamentoMaximo.value,
                // embalagem: embalagem.value,
                // tipoEmbalagem: tipoEmbalagem.value,
                // toxicidade: toxicidade.value,
                // transporte: transporte.value,
                // sustentabilidade: sustentabilidade.value,
            };

            const response = await axios.post('https://api.beat.iotechpis.com/api/products/', {
                data: novoProduto,
            });

            console.log('Produto adicionado com sucesso:', response.data);
            // Clear the form fields after successful submission
            nome.value = '';
            ewcCode.value = '';
            descricao.value = '';
            tipoResiduo.value = '';
            estadoFormaResiduo.value = '';
            natureza.value = '';
            cor.value = '';
            condicoesArmazenamento.value = '';
            quantidadeEntrega.value = '';

            prazoEntrega.value = '';
            aplicacaoArmazenamento.value = '';
            estadoArmazenamento.value = '';
            tempoArmazenamentoMedio.value = '';
            tempoArmazenamentoMaximo.value = '';
            embalagem.value = '';
            tipoEmbalagem.value = '';
            toxicidade.value = '';
            transporte.value = '';
            sustentabilidade.value = '';
            selectedLocation = ref<null>(null);
            markerPosition = null;

            dialog.value = false;
        } catch (error) {
            console.error('Erro ao adicionar produto:', error);
        }
    };

    //handle step change
    async function onStepChange(val: number) {
        console.log('Step:', val);
        if (val == 3) {
            const loader = new Loader({
                apiKey: import.meta.env.VITE_GMAPS_KEY,
                libraries: ['places'],
            });

            await loader.load();
            const mapContainer = document.getElementById('map');

            if (mapContainer) {
                const position: GeolocationPosition = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });

                map = new google.maps.Map(mapContainer, {
                    center: { lat: position.coords.latitude, lng: position.coords.longitude },
                    zoom: 12,
                });

                markerPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

                marker = new google.maps.Marker({
                    position: markerPosition,
                    map: map,
                    draggable: true,
                });

                marker.addListener('dragend', () => {
                    markerPosition = marker.getPosition() || null;
                });

                const input = document.createElement('input');
                input.setAttribute('id', 'pac-input');
                input.setAttribute('class', 'controls');
                input.setAttribute('type', 'text');
                input.setAttribute('placeholder', 'Search Box');
                map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

                const searchBox = new google.maps.places.SearchBox(input);
                map.addListener('bounds_changed', () => {
                    searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
                });

                searchBox.addListener('places_changed', () => {
                    const places = searchBox.getPlaces();
                    if (places && places.length > 0) {
                        const place = places[0];
                        if (place.geometry && place.geometry.location) {
                            map.setCenter(place.geometry.location);
                            marker.setPosition(place.geometry.location);
                            markerPosition = place.geometry.location;
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .color-box {
        width: 100%;
        height: 100px;
        margin-top: 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
    }

    #map {
        height: 300px;
    }
    .controls {
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 2px 0 0 2px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        margin-top: 10px;
        margin-left: 10px;
        height: 32px;
        outline: none;
        padding: 0 11px 0 13px;
        width: 400px;
        font-size: 14px;
        text-overflow: ellipsis;
    }
</style>
