<template>
  <div
    :class="mdAndUp ? 'tw-w-11/12' : 'flex-column'"
    class="fill-height justify-center bg-bg tw-flex tw-max-w-5xl tw-flex-col"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <v-form ref="form" class="tw-flex tw-flex-col">
        <v-row class="justify-center">
          <p class="text-dark_green font-weight-bold text-subtitle-1 text-md-h5">
            Bem-vindo!
          </p>
        </v-row>
        <v-row class="justify-center text-center">
          <p class="text-dark_green text-subtitle-1 text-md-h6 mt-md-2">
            Escolha o seu perfil
          </p>
        </v-row>

        <v-row class="button-container mt-4 tw-flex tw-flex-row tw-w-max">
          <v-btn
            v-for="option in profileOptions"
            :key="option"
            :class="{ 'active': selectedProfile === option }"
            @click="selectProfile(option)"
          >
            {{ option }}
          </v-btn>
        </v-row>

        <registerBuyer v-if="selectedProfile === 'consumidor'" />
        <registerProducer v-if="selectedProfile === 'fornecedor'" />
        <registerShipping v-if="selectedProfile === 'transportador'" />
      </v-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
import registerBuyer from '@/views/Auth/RegisterBuyer.vue';
import registerProducer from '@/views/Auth/RegisterProducer.vue';
import registerShipping from '@/views/Auth/RegisterShipping.vue';

const { mdAndUp } = useDisplay();
const form = ref<HTMLFormElement>();
const selectedProfile = ref<string | null>(null);

const profileOptions = ['consumidor', 'fornecedor', 'transportador'];

const selectProfile = (profile: string) => {
  selectedProfile.value = profile;
};
</script>

<style lang="scss" scoped>
.text1 {
  background-color: #b18cfe !important;
  box-shadow: none !important;
  color: white !important;
  font-size: large !important;
}

.text2 {
  background-color: transparent !important;
  box-shadow: none !important;
  color: black !important;
  font-size: large !important;
}

.button-container {
  margin: 0 auto;
  padding: 2px;
  gap: 4px;
}

.active {
  background-color: #b18cfe !important;
  color: white !important;
}
</style>
