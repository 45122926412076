<template>
    <div class="tw-flex tw-flex-col tw-gap-3">
        <div class="tw-relative">
            <io-img
                :aspect-ratio="1"
                contain
                class="tw-w-full tw-rounded-xl tw-border tw-border-gray-300"
                :media="firstPicture"
                content-type="Supplier"
                format="medium">
                <div>
                    <v-btn
                        @click="toggleFavorite"
                        position="absolute"
                        class="tw-right-3 tw-top-3"
                        :style="{ background: 'rgba(255, 255, 255, 0.8)' }"
                        icon
                        size="3.5rem">
                        <v-icon
                            :color="isFavorite ? 'secondary' : 'green-900'"
                            size="2.5rem">
                            {{ isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                        </v-icon>
                    </v-btn>
                </div>
            </io-img>
        </div>

        <div class="tw-flex tw-justify-between max-md:tw-flex max-md:tw-flex-col lg:tw-items-center">
            <h4 class="tw-text-xs xl:tw-text-2xl">
                {{ supplier.name }}
            </h4>
            <v-btn
                :to="{ name: 'Supplier', params: { id: supplier.id } }"
                class="tw-bg-primary tw-text-xs tw-text-green-900"
                size="x-small">
                {{ $t('shared.seeDetails') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { PropType, ref, defineProps } from 'vue';
    import { Media, Supplier } from '../../contentTypes';
    import { useDisplay } from 'vuetify';
    import ioImg from '@/components/ioImg.vue';
    import { useFavorites } from '@/composables/useFavorites';

    const props = defineProps({
        supplier: {
            type: Object as PropType<Supplier>,
            required: true,
        },
    });

    const { xlAndUp } = useDisplay();
    const $favorites = useFavorites();
    const favoriteId = ref(props.supplier.favoriteId || undefined);

    const firstPicture = (props.supplier?.picture as Media) || undefined;

    const isFavorite = ref(!!props.supplier?.favoriteId);

    const toggleFavorite = async (supplierId?: number) => {
        try {
            if (supplierId === undefined) {
                supplierId = props.supplier.id;
            }

            if (!isFavorite.value) {
                const { data } = await $favorites.addFavorite('vendor', String(props.supplier.id));
                favoriteId.value = data.data.id;
                isFavorite.value = true;
            } else {
                await $favorites.removeFavorite(favoriteId.value as number);
                favoriteId.value = undefined;
                isFavorite.value = false;
            }
        } catch (error) {
            console.error('Error toggling favorite:', error);
        }
    };
</script>

<style scoped></style>
