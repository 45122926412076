<template class="tw-px-[var(--padding-left)] tw-py-[var(--padding-top)]">
    <div class="tw-flex tw-items-center tw-justify-between sm:tw-hidden">
        <router-link to="/profile">
            <v-btn
                class="tw-text-2xl"
                icon="mdi-chevron-left"
                variant="tonal"
                color="green-900"></v-btn>
        </router-link>

        <h1 class="tw-text-2xl">{{ $t('profile.info.title') }}</h1>
        <v-btn
            class="tw-text-2xl"
            :icon="!isEditing ? 'mdi-square-edit-outline' : 'mdi-content-save'"
            variant="text"
            @click="onSubmit"
            color="green-900"></v-btn>
    </div>
    <div class="tw-mt-8 tw-flex max-lg:tw-flex-col max-lg:tw-justify-center lg:tw-mt-12 lg:tw-items-start lg:tw-gap-8 2xl:tw-gap-16">
        <io-img
            :aspect-ratio="1"
            cover
            format="thumbnail"
            class="tw-w-full tw-max-w-[13.75rem] tw-rounded-4xl max-lg:tw-mx-auto lg:tw-max-w-xs 2xl:tw-max-w-md"
            :media="user?.picture"
            content-type="Media"></io-img>
        <v-form class="tw-col-span-2 tw-flex tw-grow tw-flex-col tw-gap-4 lg:tw-grid lg:tw-grid-cols-2 lg:tw-items-start 2xl:tw-gap-x-16 2xl:tw-gap-y-12">
            <div>
                <v-label class="tw-mb-2 tw-text-lg sm:tw-text-xl">{{ $t('fields.name') }}</v-label>
                <v-text-field
                    v-model.trim="user.username"
                    class="auth-input"
                    :readonly="!isEditing"
                    :rules="[rules.required]"
                    type="text"
                    name="username">
                </v-text-field>
            </div>
            <div>
                <v-label class="tw-mb-2 tw-text-lg sm:tw-text-xl">{{ $t('fields.email') }}</v-label>
                <v-text-field
                    v-model.trim="user.email"
                    class="auth-input"
                    :readonly="!isEditing"
                    :rules="[rules.required, rules.email]"
                    type="email"
                    name="email"></v-text-field>
            </div>
            <div>
                <v-label class="tw-mb-2 tw-text-lg sm:tw-text-xl">{{ $t('fields.area') }}</v-label>
                <v-autocomplete
                    clearable
                    :items="['operationArea1', 'operationArea2', 'operationArea3', 'operationArea4', 'operationArea5']"
                    v-model="user.operationArea"
                    :readonly="!isEditing"
                    class="custom-padding"></v-autocomplete>
            </div>
            <div class="tw-self-end tw-text-left max-sm:tw-hidden">
                <v-btn
                    v-if="isEditing"
                    :loading="isLoading"
                    type="submit"
                    size="x-large"
                    color="primary">
                    {{ $t('shared.save') }}
                </v-btn>
                <v-btn
                    v-else
                    @click="isEditing = !isEditing"
                    size="x-large"
                    color="primary">
                    {{ $t('shared.edit') }}
                </v-btn>
            </div>
        </v-form>
    </div>
    <div class="tw-flex tw-justify-end">
        <v-btn
            size="x-large"
            color="red"
            @click="logout"
            >{{ $t('views.profile.info.logout') }}</v-btn
        >
    </div>
</template>

<script setup lang="ts">
    import { User } from '@/contentTypes';
    import { ref } from 'vue';
    import useRules from '@/composables/rules';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import ioImg from '@/components/ioImg.vue';

    const $router = useRouter();
    const rules = useRules();
    const isEditing = ref(false);
    const isLoading = ref(false);
    const authStore = useAuthStore();

    const user = ref<User>(authStore.getUser);

    function logout() {
        authStore.logout();
        $router.push({
            name: 'Home',
        });
    }

    async function onSubmit() {
        if (!isEditing.value) {
            isEditing.value = true;
            return;
        }
        isEditing.value = false;
    }
</script>

<style>
    .custom-padding .v-input__control .v-field {
        @apply tw-py-2;
    }
</style>
