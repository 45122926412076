<template>
    <div class="tw-flex tw-flex-col max-lg:tw-pt-[var(--padding-top)]">
        <p class="tw-text-center tw-text-green-900 max-lg:tw-text-2xl lg:tw-text-5xl xl:tw-mb-4">{{ $t('routes.Marketplace') }}</p>

        <v-text-field
            v-model="searchText"
            placeholder="pesquisar"
            hide-details
            @keydown.enter="onSearch"
            class="search-input tw-mx-auto tw-w-full tw-max-w-4xl tw-items-center tw-justify-center tw-gap-8 tw-pt-6">
            <template #append-inner>
                <v-btn
                    class="tw-absolute tw-right-0 tw-mr-1 tw-max-h-none tw-bg-green-900 tw-px-10 tw-text-white max-md:tw-hidden lg:tw-h-5/6"
                    @click="onSearch">
                    {{ $t('views.shippingCompany.shippings.search') }}
                </v-btn>
            </template>
        </v-text-field>

        <div class="tw-flex tw-justify-center tw-gap-x-4 tw-px-[var(--padding-right)] tw-text-center">
            <div>
                <v-btn
                    @click="toggleCategory(category)"
                    v-for="category in categories"
                    :key="category?.id"
                    class="tw-mb-2 tw-mr-2 tw-bg-green-900"
                    :class="{ 'tw-bg-primary !tw-text-green-900': selectedCategory?.id === category?.id, 'tw-text-white': selectedCategory?.id !== category?.id }"
                    size="large">
                    {{ category?.name }}
                </v-btn>
            </div>

            <div>
                <v-btn
                    class="botaoFiltros"
                    size="large"
                    variant="outlined"
                    color="green-900"
                    @click="toggleDrawer">
                    <v-icon>mdi-filter-outline</v-icon>
                    <p class="tw-text-sm max-md:tw-hidden">{{ $t('views.shippingCompany.shippings.filters') }}</p>
                </v-btn>

                <v-btn
                    size="large"
                    variant="outlined"
                    color="green-900"
                    class="lg:tw-hidden"
                    :class="{ 'link-ativo': mostrarComponenteMapa }"
                    @click="mostrarMapa">
                    <v-icon>mdi-map</v-icon>
                </v-btn>
            </div>
        </div>
        <div class="tw-text-green-90 tw-flex tw-flex-row tw-justify-end tw-gap-4 tw-px-[var(--padding-right)] max-lg:tw-hidden">
            <a
                href="#"
                class="tw-font-bold"
                :class="{ 'link-ativo': mostrarComponenteLista }"
                @click="mostrarLista">
                Lista
            </a>
            <p>/</p>
            <a
                href="#"
                class="tw-font-bold"
                :class="{ 'link-ativo': mostrarComponenteMapa }"
                @click="mostrarMapa">
                Mapa
            </a>
        </div>

        <div v-if="mostrarComponenteLista">
            <List :query="query" />
        </div>
    </div>
    <div
        v-if="mostrarComponenteMapa"
        class="tw-mt-4">
        <Map :query="query" />
    </div>
    <Filters v-model="filtersOpen" />
</template>

<script setup lang="ts">
    import Filters from './Filters.vue';
    import axios from '@/plugins/axios';
    import List from './List.vue';
    import Map from './Map.vue';
    import { onMounted, ref } from 'vue';
    import { computed } from 'vue';
    //@ts-ignore
    import { Category, Product, Product_Type, Product_QuantityType } from '@/contentTypes.d.ts';
    import { useRoute, useRouter } from 'vue-router';
    import _ from 'lodash';
    import { watch } from 'vue';

    const $route = useRoute();
    const $router = useRouter();
    const categories = ref<Category[]>([]);
    const products = ref<Product[]>([]);
    const filtersOpen = ref(false);
    const selectedCategory = ref<Category | null>(null);

    async function fetchCategories() {
        try {
            const response = await axios.get('/categories');
            categories.value = response.data.data;
        } catch (error) {
            console.error('Erro ao obter categorias:', error);
        }
    }

    const mostrarComponenteLista = ref(false);
    const mostrarComponenteMapa = ref(false);
    const searchText = ref('');
    const searchQuery = ref('');

    const query = computed(() => {
        const filters: Record<string, any> = {};
        _.set(filters, 'isFake', {
            $eq: false,
        });

        if (searchQuery.value) {
            _.set(filters, 'name', {
                $containsi: searchQuery.value,
            });
        }

        if (selectedCategory.value) {
            _.set(filters, 'category', {
                id: selectedCategory.value.id,
            });
        }

        if ($route.query.priceRange) {
            _.set(filters, 'price', {
                $gte: $route.query.priceRange[0],
                $lte: $route.query.priceRange[1],
            });
        }

        if ($route.query.quantity) {
            _.set(filters, 'quantity', {
                $gte: $route.query.quantity,
            });
        }

        if ($route.query.location) {
            _.set(filters, 'location', {
                $containsi: $route.query.location,
            });
        }

        if ($route.query.productType) {
            _.set(filters, 'type', {
                $eq: $route.query.productType,
            });
        }

        if ($route.query.isFinished) {
            _.set(filters, 'isFinished', {
                $eq: $route.query.isFinished,
            });
        }

        if ($route.query.matterState) {
            _.set(filters, 'matterState', {
                $eq: $route.query.matterState,
            });
        }

        if ($route.query.color) {
            _.set(filters, 'color', {
                $eq: $route.query.color,
            });
        }

        if ($route.query.season) {
            _.set(filters, 'season', {
                $eq: $route.query.season,
            });
        }

        if ($route.query.shortCircuit) {
            _.set(filters, 'shortCircuit', {
                $eq: $route.query.shortCircuit,
            });
        }

        if ($route.query.nature) {
            _.set(filters, 'nature', {
                $eq: $route.query.nature,
            });
        }

        return { filters };
    });

    onMounted(() => {
        mostrarLista();
        fetchCategories();
        // fetchProducts();
    });

    function onSearch() {
        searchQuery.value = searchText.value;
    }

    function mostrarLista() {
        mostrarComponenteLista.value = true;
        mostrarComponenteMapa.value = false;
    }

    function mostrarMapa() {
        mostrarComponenteMapa.value = !mostrarComponenteMapa.value;
        mostrarComponenteLista.value = !mostrarComponenteMapa.value;
        $router.push({ query: { view: mostrarComponenteMapa.value ? 'map' : 'list' } });
    }

    function toggleDrawer() {
        filtersOpen.value = !filtersOpen.value;
    }

    function toggleCategory(category: Category) {
        if (selectedCategory.value?.id === category.id) {
            selectedCategory.value = null;
        } else {
            selectedCategory.value = category;
        }
    }
</script>

<style>
    @media screen and (min-width: 1024px) {
        .search-input {
            input {
                padding-block: 1.25rem !important;
                padding-left: 2rem !important;
                padding-right: 1rem !important;
            }
        }
    }

    .link-ativo {
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: black;
    }

    .quantity-input {
        grid-template-columns: 0 1fr 1fr;
        .v-input__append {
            margin-inline-start: 0px !important;
        }
        .v-field.v-field--appended {
            padding: 0px !important;
        }
        & > .v-input__control > .v-field .v-field__outline__end {
            border-end-end-radius: 0px !important;
            border-start-end-radius: 0px !important;
        }
        & > .v-input__append .v-input__control > .v-field .v-field__outline__start {
            border-end-start-radius: 0px !important;
            border-start-start-radius: 0px !important;
            border-inline-start-width: 0px !important;
        }
    }
</style>
