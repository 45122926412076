<template class="tw-px-[var(--padding-left)] tw-py-[var(--padding-top)]">
        <div class="tw-flex tw-items-center sm:tw-hidden">
        <router-link to="/profile">
            <v-btn
                class="tw-text-2xl"
                icon="mdi-chevron-left"
                variant="tonal"
                color="green-900"></v-btn>
        </router-link>

        <h1 class="tw-text-2xl tw-pl-4">{{ $t('views.profile.orders.activeOrders') }}</h1>
    </div>
    
    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 2xl:tw-gap-16">
        <CardOrder
            v-for="order in orders"
            :key="order.id" :order="order" />
    </div>
</template>

<script setup lang="ts">
    import CardOrder from './CardOrder.vue';

    // const orderStatuses = ['completed', 'pending', 'cancelled', 'processing', 'delivering'];
    const orderStatuses = ['pending', 'processing', 'delivering'];

    const orders: any[] = [];

    for (let i = 0; i < 10; i++) {
        orders.push({
            // id must be random
            id: Math.floor(Math.random() * 1000),
            status: orderStatuses[Math.floor(Math.random() * orderStatuses.length)],
            description: 'O vendedor não aceitou a sua solicitação',
        });
    }
</script>

<style scoped></style>
