<template>
    <div>
        <v-row>
            <v-col
                class="my-custom-form-col"
                cols="12">
                <label
                    class="text-dark_green text-md-h6 md:tw-font-medium"
                    for="name"
                    >Nome</label
                >
                <v-text-field
                    class="mt-2"
                    hide-details="auto"
                    v-model="name"
                    name="name"
                    variant="outlined"
                    density="default"
                    placeholder="Nome"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                class="my-custom-form-col"
                cols="12">
                <label
                    class="text-dark_green text-md-h6 md:tw-font-medium"
                    for="username"
                    >username</label
                >
                <v-text-field
                    class="mt-2"
                    hide-details="auto"
                    v-model="username"
                    name="username"
                    variant="outlined"
                    density="default"
                    placeholder="Username"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <label
                    class="text-dark_green text-md-h6 md:tw-font-medium"
                    for="email"
                    >Email</label
                >
                <v-text-field
                    class="mt-2"
                    hide-details="auto"
                    v-model="email"
                    name="email"
                    variant="outlined"
                    density="default"
                    placeholder="Email"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <label
                    class="text-dark_green text-md-h6 md:tw-font-medium"
                    for="password"
                    >Password</label
                >
                <v-text-field
                    class="mt-2"
                    hide-details="auto"
                    v-model="password"
                    name="password"
                    type="password"
                    variant="outlined"
                    density="default"
                    placeholder="Password"></v-text-field>
            </v-col>
        </v-row>

        <!-- <v-row>
            <v-col>
                <v-btn
                    class="mt-2"
                    @click.prevent="getUsers"
                    >mostrar users</v-btn
                >
            </v-col>
        </v-row> -->

        <v-row>
            <v-col>
                <v-btn
                    class="mt-2"
                    @click.prevent="registerAccount"
                    >Registar</v-btn
                >
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import axios from 'axios';

    const name = ref('');
    const username = ref('');
    const email = ref('');
    const password = ref('');

    const registerAccount = async () => {
        const accountData = {
            data: {
                name: name.value,
                username: username.value,
                email: email.value,
                password: password.value,
                role: 1,
                birthday: '2002-12-02',
            },
        };

        console.log('account data teste:', accountData);

        try {
            const response = await axios.post('https://api.beat.iotechpis.com/api/users/', accountData);

            console.log('Conta criada com successo:', response.data);
        } catch (error) {
            console.error('Erro ao criar conta:', error);
        }
    };

    const getUsers = async () => {
        try {
            const response = await axios.get('https://api.beat.iotechpis.com/api/users/');
            console.log('Dados:', response.data);
        } catch (error) {
            console.error('Erro ao obter users:', error);
        }
    };
</script>

<style scoped></style>
