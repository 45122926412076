import { useI18n } from 'vue-i18n';

export const useOrder = (order: any) => {
    const $t = useI18n().t;

    const getStatus = () => {
        const status = {
            color: 'tw-text-orange-300',
            text: $t('order.status.pending'),
        };
        switch (order.status) {
            case 'processing':
                status.color = 'tw-text-green-900';
                status.text = $t('order.status.processing');
                break;
            case 'completed':
                status.color = 'tw-text-green';
                status.text = $t('order.status.completed');
                break;
            case 'cancelled':
                status.color = 'tw-text-orange';
                status.text = $t('order.status.cancelled');
                break;
            case 'delivering':
                status.color = 'tw-text-secondary';
                status.text = $t('order.status.delivering');
                break;
        }

        return status;
    };

    return {
        getStatus,
    };
};
