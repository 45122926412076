<template>
    <div>
<h1>
    ola 
</h1>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>