<template>
    <!-- class definida em main.scss -->
    <div class="grid tw-px-[var(--padding-top)] tw-pb-[var(--padding-bottom)]">
        <CardProduct
            v-for="product in products"
            :key="product.id"
            :product="product" />
        <v-overlay
            :model-value="isLoading"
            contained
            absolute
            :close-on-back="false"
            :close-on-content-click="false"
            :persistent="true"
            class="tw-items-center tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"></v-progress-circular>
        </v-overlay>
    </div>
    <div v-if="!products.length && !isLoading">
        <p class="tw-text-center tw-pb-48">
            {{ $t('views.products.list.ProductsFilteredDontExist') }}
        </p>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import CardProduct from './CardProduct.vue';
    import { Product } from '../../contentTypes';
    import axios from '@axios';
    import qs from 'qs';
import { useI18n } from 'vue-i18n';

    const  {locale} = useI18n();

    const props = defineProps({
        query: {
            type: Object,
            default: () => ({}),
        },
    });

    const products = ref<Product[]>([]);
    const isLoading = ref(true);

    async function getProducts() {
        isLoading.value = true;
        try {
            const query = qs.stringify({
                populate: ['category', 'pictures'],
                ...props.query,
            });
            const response = await axios.get(`/products?${query}`);
            products.value = response.data.data;
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            isLoading.value = false;
        }
    }

    watch([() => props.query], () => {
        getProducts();
    });

    watch(locale, () => {
        getProducts();
    });

    getProducts();
</script>

<style scoped></style>
